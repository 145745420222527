import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bulma/css/bulma.min.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Settings } from './types/settings'
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import { rootReducer } from "./reducers";
import thunk from "redux-thunk";
import { authService } from "./services/AuthService";

declare global {
    interface Window {
        settings: Settings;
        store: any;
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
);

window.store = store;

(async () => {
    const settingsResponse = await fetch('/settings.json');
    const settingsJson = await settingsResponse.json();
    window.settings = settingsJson;

    authService.initialize();
    await authService.handleRedirect();

    if (!await authService.isAuthenticated()) {
        await authService.signIn();
        return;
    }

    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>, document.getElementById("root"));
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
