import LoginFormComponent, { LoginFormProps } from "../components/LoginFormComponent/LoginFormComponent";
import { login, LoginUserRequest } from '../actions/loginActions';
import React from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

interface DispatchProps {
    login: (payload: LoginUserRequest) => any;
}

class LoginForm extends React.Component<DispatchProps> {
    onSubmit = (formValues: LoginFormProps) => {
        this.props.login(formValues);
    }

    render() {
        return (
            <LoginFormComponent onSubmit={this.onSubmit} />
        );
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): DispatchProps => ({
    login: (payload: LoginUserRequest) => dispatch(login(payload))
});

export default connect(null, mapDispatchToProps)(LoginForm);