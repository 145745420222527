import * as actionTypes from "../constants/loginActionTypes";
import { LoginAction } from "../constants/loginActionTypes";
import { AsyncAction } from ".";
import { authService } from "../services/AuthService";

export interface LoginUserRequest {
    userName: string;
    password: string;
}

export const login = (): AsyncAction<LoginAction> => {
    return async (dispatch) => {
        const token = await authService.getToken();

        return dispatch({ type: actionTypes.LOGIN, token });
    };
}
