import * as actionTypes from "../constants/ap304ActionTypes";
import Ap304Api from '../api/Ap304Api';
import { GetAp304BestandenAction, GetAp304BestandenTotalAction } from "../constants/ap304ActionTypes";
import { AsyncAction } from ".";
import { setIsLoading } from "./gridviewActions";

export const getAp304BestandProgress = (selectedApotheken: number[], from: string, to: string, useIsLoading:boolean): AsyncAction<GetAp304BestandenAction> => {
    return async (dispatch) => {
        useIsLoading && dispatch(setIsLoading(true));
        const ap304BestandProgress = 
            await Ap304Api.getAp304BestandProgress(selectedApotheken, from, to
                ).finally(() => {
                    useIsLoading && dispatch(setIsLoading(false));
            });

            //setTimeout(() => dispatch(setIsLoading(false)), 3500);


        return dispatch({ type: actionTypes.GET_AP304BESTANDEN, ap304BestandProgress });
    };
}

export const getAp304BestandTotal = (selectedApotheken: number[], from: string, to: string): AsyncAction<GetAp304BestandenTotalAction> => {
    return async (dispatch) => {
        const ap304BestandTotal = await Ap304Api.getAp304BestandTotal(selectedApotheken, from, to);

        return dispatch({ type: actionTypes.GET_AP304BESTANDENTOTAL, ap304BestandTotal });
    };
}