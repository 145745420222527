import * as types from "../constants/gridviewActionTypes";

const initialState = [{ gridviewId: "", sorting: [], value: "" }]

type State = typeof initialState

export const filterDateReducer = (
    state: State = initialState,
    action: types.SetGridviewAction
) => {
    // let gridview = state.find(g => g.gridviewId === action.gridviewId);
    // if (!gridview && action.gridviewId) {
    //     gridview = { gridviewId: action.gridviewId, sorting: [], filters: [], };
    //     state = [...state, gridview];
    // }

    // if (action.type === types.SET_GRIDVIEW)
    //     const filter = gridview.filters.find(s => s.id === action.filterId);

    //     if (!filter)
    //         gridview.push({ id: action.filterId, value: action.value })
    //     else
    //         filter.value = action.value;
    //     return { ...state, gridviewId: action.gridviewId, filterId: action.filterId, value: action.value };

    return state;
};

const initialLoadingState = true as boolean;
type LoadingState = typeof initialLoadingState;
export const loadingReducer = (
    state: LoadingState = initialLoadingState,
    action: types.SetIsLoading
) => {
    if (action.type === types.SET_ISLOADING)
        return action.isLoading;
    return state;
};