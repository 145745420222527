import * as React from "react";
import Gridview from "../Gridview/Gridview"
import { Statistieken, Ap304BestandTotal, Ap304BestandProgress, ApotheekAp304 } from "../../types/model";
import { Column, Filter, SortingRule } from "react-table";
import MonthPicker from "../MonthPicker";
import ProgressBar from "../ProgressBar/ProgressBar";

interface OwnProps {
    onChange: (from: string, to: string) => void;
    onRowClick?: (event: any, rowInfo: any) => void;
    onFilteredChanged: (filter: string, value: string) => void;
    onSortingChanged: (column: string, desc: boolean) => void;
    columns: Array<Column>;
    from: string;
    to: string;
    apotheekAp304: ApotheekAp304[];
    ap304BestandTotal: Ap304BestandTotal;
    ap304BestandProgress: Ap304BestandProgress;
    isLoading: boolean;
    usePaging: boolean;
    filteredSettings: Filter[];
    sortingSettings: SortingRule[];
}

const names = ["Ingediend", "TRINGL", "VECOZO", "Retour ZVZ"];

export default class DashboardComponent extends React.Component<OwnProps> {
    render() {
        const { onChange, from, to, onRowClick, onFilteredChanged, onSortingChanged, columns, apotheekAp304, ap304BestandTotal, ap304BestandProgress, isLoading, usePaging } = this.props;

        let currentStep: number = 1

        let statistics: Statistieken[] = []
        if (ap304BestandProgress) {
            if (ap304BestandProgress.toegekendStatistieken.aantalBestanden > 0) {
                currentStep = 4
            }
            else if (ap304BestandProgress.opgestuurdStatistieken.aantalBestanden > 0) {
                currentStep = 3
            }
            else if (ap304BestandProgress.tringlStatistieken.aantalBestanden > 0) {
                currentStep = 2
            }
            else {
                currentStep = 1;
            }

            statistics = [ap304BestandProgress.importStatistieken, ap304BestandProgress.tringlStatistieken, ap304BestandProgress.opgestuurdStatistieken, ap304BestandProgress.toegekendStatistieken]
        }

        return (
            <>
                <div className="column is-full">
                    <div className="bodyHeader column is-full">
                        <div className="column is-one-third">
                            <h1>Dashboard</h1>
                            <div className="totalGrid">
                                <div className="column is-half">
                                    <h1>{ap304BestandTotal.totaalRegels}</h1>
                                    <span>Declaratieregels</span>
                                </div>
                                <div className="column is-half">
                                    <h1>{new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(ap304BestandTotal.totaalBedrag)}</h1>
                                    <span>Euro gedeclareerd</span>
                                </div>
                                <div className="column is-half">
                                    <h1>{apotheekAp304 != null ? apotheekAp304.length : 0}</h1>
                                    <span>Instellingen aangesloten</span>
                                </div>
                                <div className="column is-half">
                                    <h1>{ap304BestandTotal.aantalPatienten}</h1>
                                    <span>Patienten</span>
                                </div>
                            </div>
                        </div>
                        <div className="column is-two-thirds align-top">
                            <div className="column is-one-third">
                                <h1>Huidige vooruitgang</h1>
                            </div>
                            <MonthPicker
                                maxOneMonthDifference={false}
                                onChange={onChange} 
                                from={from} 
                                to={to}
                            />
                            <div className="column is-full">
                                <ProgressBar stepCount={4} currentStep={currentStep} names={names} statistics={statistics} />
                            </div>
                        </div>
                    </div>
                    <div className="bodyHeader column is-full">
                        {apotheekAp304.map( (value:ApotheekAp304) => 
                            <div key={value.id} className="apotheekLabel">
                                {value.apotheeknaam}
                            </div>)
                        }
                    </div>
                </div>
                <div className="column is-full whiteBackground">
                    <div className="body content">
                        <Gridview filterSettings={this.props.filteredSettings} sortingSettings={this.props.sortingSettings} usePaging={usePaging} onRowClick={onRowClick} onFilteredChanged={onFilteredChanged} onSortingChanged={onSortingChanged} columns={columns} data={apotheekAp304 != null ? apotheekAp304 : []} height="50vh" isLoading={isLoading} />
                    </div>
                </div>
            </>
        );
    }
}
