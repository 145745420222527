import * as types from "../constants/index";
import { TringlAlert } from "../types/model";

const initialState: Array<TringlAlert> = [];

type State = typeof initialState

export const alertReducer = (state: State = initialState, action: types.GetAlertsAction) => {
    if(action.type === types.GET_TRINGLALERTS)
        return action.alerts;

    return state;
}