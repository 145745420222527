import ApiClient from './ApiClient';

import { IncontinentieProfiel } from "../types/model";

const endpoint = 'incontinentieProfiel';

export default class ApotheekApi {
    public static async getIncontinentieProfielen(bsnnummer?: number, naam?: string, geboortedatum?: string): Promise<IncontinentieProfiel[]> {
        var incontinentieProfielen = await ApiClient.doHttpRequest<IncontinentieProfiel[]>('get', `${endpoint}/${bsnnummer ? bsnnummer : " " }/${naam ? naam : " " }/${geboortedatum ? geboortedatum : "1-1-0001" }`);
        return incontinentieProfielen;
    }

    public static async getIncontinentieProfiel(id: number): Promise<IncontinentieProfiel> {
        var incontinentieProfiel = await ApiClient.doHttpRequest<IncontinentieProfiel>('get', `${endpoint}/${id}`);
        return incontinentieProfiel;
    }

    public static async addVerzekerdeZvZProfiel(profielId : number, verzekerdeId :number , startDatum : string): Promise<void> {
        await ApiClient.doHttpRequest<IncontinentieProfiel>('post', `${endpoint}/addVerzekerdeZvZProfiel`, {profielId, verzekerdeId, startDatum});
    }

    public static async stopVerzekerdeZvZProfiel(verzekerdeId :number , eindDatum : string): Promise<void> {
        await ApiClient.doHttpRequest<IncontinentieProfiel>('post', `${endpoint}/stopVerzekerdeZvZProfiel`, {verzekerdeId, eindDatum});
    }
}
