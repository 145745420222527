import ApiClient from "./ApiClient";

import {
  Factuur,
  Patient,
  Middel,
  Actie,
  DeclaratieRegel,
  CreateHerdeclaratieModel,
} from "../types/model";

const endpoint = "prestatie";

export default class PrestatieApi {
  public static async getPrestatiesForFacturen(
    selectedApotheken: number[],
    from: string,
    to: string,
    factuurNummer: string
  ): Promise<Factuur[]> {
    var facturen = await ApiClient.doHttpRequest<Factuur[]>(
      "post",
      `${endpoint}/prestatiesforfacturen`,
      { selectedApotheekIds: selectedApotheken, from, to, factuurNummer }
    );

    return facturen;
  }

  public static async getHerdeclaraties(
    selectedApotheken: number[]
  ): Promise<Factuur[]> {
    var facturen = await ApiClient.doHttpRequest<Factuur[]>(
      "post",
      `${endpoint}/herdeclaraties`,
      { selectedApotheekIds: selectedApotheken }
    );

    return facturen;
  }

  public static async createHerdeclaratie(
    model: CreateHerdeclaratieModel
  ): Promise<void> {
    await ApiClient.doHttpRequest<void>(
      "post",
      `${endpoint}/herdeclaratie`,
      model
    );
  }

  public static async getPrestatiesForPatienten(
    selectedApotheken: number[],
    from: string,
    to: string,
    bsnnummer?: number,
    naam?: string,
    geboortedatum?: string
  ): Promise<Patient[]> {
    var patienten = await ApiClient.doHttpRequest<Patient[]>(
      "post",
      `${endpoint}/prestatiesforpatienten`,
      {
        selectedApotheekIds: selectedApotheken,
        from,
        to,
        bsnnummer,
        naam,
        geboortedatum,
      }
    );

    return patienten;
  }

  public static async getPrestatiesForMiddelen(
    selectedApotheken: number[],
    from: string,
    to: string
  ): Promise<Middel[]> {
    var middelen = await ApiClient.doHttpRequest<Middel[]>(
      "post",
      `${endpoint}/prestatiesformiddelen`,
      { selectedApotheekIds: selectedApotheken, from, to }
    );

    return middelen;
  }

  public static async getPrestatiesForActies(
    selectedApotheken: number[],
    from: string,
    to: string
  ): Promise<Actie[]> {
    var acties = await ApiClient.doHttpRequest<Actie[]>(
      "post",
      `${endpoint}/prestatiesforacties`,
      { selectedApotheekIds: selectedApotheken, from, to }
    );

    return acties;
  }

  public static async getPrestatieForDeclaratieRegel(
    selectedApotheken: number[],
    id: number
  ): Promise<DeclaratieRegel> {
    var prestatie = await ApiClient.doHttpRequest<DeclaratieRegel>(
      "post",
      `${endpoint}/prestatiefordeclaratieregel`,
      { selectedApotheekIds: selectedApotheken, id }
    );

    return prestatie;
  }

  public static async setPrestatieStatus(
    prestatieId: number,
    status: boolean
  ): Promise<void> {
    await ApiClient.doHttpRequest<void>(
      "post",
      `${endpoint}/setprestatiestatus`,
      { prestatieid: prestatieId, status }
    );
  }

  public static async getPatienten(
    bsnnummer?: string,
    naam?: string,
    geboortedatum?: string
  ): Promise<void> {
    await ApiClient.doHttpRequest<Patient[]>(
      "post",
      `${endpoint}/prestatiesforpatienten`,
      { bsnnummer, naam, geboortedatum }
    );
  }
}
