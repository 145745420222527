import ApiClient from './ApiClient';

import { TringlAlert } from "../types/model";

const endpoint = 'alert';

export default class AlertApi {
    public static async getAlerts(selectedApotheken: number[], from: string, to: string): Promise<TringlAlert[]> {
        var alerts = await ApiClient.doHttpRequest<TringlAlert[]>('post', `${endpoint}/getalerts`, { selectedApotheekIds: selectedApotheken, from, to });

        return alerts;
    }

    public static async updateAlert(id: number, isOpgelost: boolean): Promise<void> {
        await ApiClient.doHttpRequest<void>('post', `${endpoint}/updatealert`, { id: id, isOpgelost });
    }
}