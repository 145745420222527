import * as React from "react";
import { Field, reduxForm, InjectedFormProps } from "redux-form";
import { LabelInput } from "../LabelInput";
import { TextInput} from "../TextInput";

export interface WachtwoordProps {
    oldPassword: string,
    newPassword: string,
    confirmPassword: string
}

//const DeclaratieRegelDetailComponent: React.FunctionComponent<OwnProps & InjectedFormProps<{}, OwnProps>> = (props) =>
const AccountComponent: React.FunctionComponent<InjectedFormProps<WachtwoordProps>> = (props) => {
    const { handleSubmit } = props;

    return (
        <>
            <h1 className="header">Settings</h1>
            <div className="column is-half maincontent">
                <div className="column contentPanel is-full">
                    <form onSubmit={handleSubmit} >
                        <div className="column subHeader is-full">
                            <h1>Account</h1>
                        </div>

                        <Field
                            component={LabelInput}
                            label="Voornaam"
                            name="voornaam"
                            showSideBySide={true}
                            text={"Admin"}
                        />

                        <Field
                            component={LabelInput}
                            label="Achternaam"
                            name="achternaam"
                            showSideBySide={true}
                            text={"Tringl"}
                        />

                        <div className="column subHeader is-full">
                            <h1>Wachtwoord</h1>
                        </div>

                        <Field
                            component={TextInput}
                            label="Oud Wachtwoord"
                            name="oldPassword"
                            showSideBySide={false}
                            type="password"
                        />
                        <Field
                            component={TextInput}
                            label="Nieuw Wachtwoord"
                            name="newPassword"
                            showSideBySide={true}
                            type="password"
                        />

                        <Field
                            component={TextInput}
                            label="Wachtwoord bevestigen"
                            name="confirmPassword"
                            showSideBySide={true}
                            type="password"
                        />
                        <div className="bottomBorder"></div>


                        <div className="columns contentPanel">
                            <div className="column is-full">
                                <div className="buttons is-pulled-right">
                                    <button type="submit" className="button is-success" >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );

}

export default reduxForm<WachtwoordProps>({
    form: "AccountForm"
})(AccountComponent);