import * as types from "../constants/ap304ActionTypes";
import { Ap304BestandProgress, Ap304BestandTotal } from "../types/model";

const initialStatistieken = { aantalBestanden: 0, bedrag: 0, regels: 0, aantalPatienten: 0 };

const initialState: Ap304BestandProgress = {
    ap304Bestanden: [],
    importStatistieken: initialStatistieken,
    tringlStatistieken: initialStatistieken,
    opgestuurdStatistieken: initialStatistieken,
    toegekendStatistieken: initialStatistieken
};

const initialTotalState: Ap304BestandTotal = {
    aantalPatienten: 0,
    totaalBedrag: 0,
    totaalRegels: 0
};

type State = typeof initialState
type StateTotal = typeof initialTotalState

export const ap304Reducer = (
    state: State = initialState,
    action: types.GetAp304BestandenAction
) => {
    if (action.type === types.GET_AP304BESTANDEN)
        return action.ap304BestandProgress;

    return state;
};

export const ap304TotalReducer = (
    state: StateTotal = initialTotalState,
    action: types.GetAp304BestandenTotalAction
) => {
    if (action.type === types.GET_AP304BESTANDENTOTAL)
        return action.ap304BestandTotal;

    return state;
};
