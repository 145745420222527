import DeclaratieRegelDetailComponent from "../components/DeclaratieRegelDetailComponent/DeclaratieRegelDetailComponent";
import { getPrestatieForDeclaratieRegel } from "../actions/prestatieActions";
import React from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from '../types/state';
import { Apotheek, DeclaratieRegel } from "../types/model";
import history from "../components/history";

interface DispatchProps {
    getPrestatieForDeclaratieRegel: (selectedApotheken: number[], id: number) => any;
}

interface StateProps {
    selectedApotheken: Apotheek[];
    declaratieRegel: DeclaratieRegel;
}

class DeclaratieRegelDetailContainer extends React.Component<DispatchProps & StateProps> {
    componentDidMount() {
        this.props.getPrestatieForDeclaratieRegel(this.props.selectedApotheken.map(a => a.id), history.location.state)
    }

    onClose() {
        history.goBack();
    }

    render() {
        const { declaratieRegel } = this.props;

        return (
            <>
                <DeclaratieRegelDetailComponent declaratieRegel={declaratieRegel} prestatieId={history.location.state} onClose={this.onClose} />
            </>
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    selectedApotheken: state.selectedApotheken,
    declaratieRegel: state.declaratieRegel
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): DispatchProps => ({
    getPrestatieForDeclaratieRegel: (selectedApotheken: number[], id: number) => dispatch(getPrestatieForDeclaratieRegel(selectedApotheken, id))
});

export default connect(mapStateToProps, mapDispatchToProps)(DeclaratieRegelDetailContainer);