import ApiClient from './ApiClient';
import { Ap304BestandProgress, Ap304BestandTotal } from "../types/model";

const endpoint = 'ap304';

export default class Ap304Api {
    public static async getAp304BestandProgress(selectedApotheekIds: number[], from: string, to: string): Promise<Ap304BestandProgress> {
        
        var ap304BestandProgress = await ApiClient.doHttpRequest<Ap304BestandProgress>(
            'post',
            `${endpoint}/ap304bestanden`,
            { selectedApotheekIds, from, to }
        );

        return ap304BestandProgress;
    }

    public static async getAp304BestandTotal(selectedApotheekIds: number[], from: string, to: string): Promise<Ap304BestandTotal> {
        var ap304BestandProgress = await ApiClient.doHttpRequest<Ap304BestandTotal>(
            'post',
            `${endpoint}/ap304bestandentotal`,
            { selectedApotheekIds, from, to }
        );

        return ap304BestandProgress;
    }
}