import React, { ChangeEvent } from "react";
import "react-month-picker/css/month-picker.css";
import classNames from "classnames";
import moment from "moment";

interface Props {
  onChange: (from: string, to: string) => void;
  maxOneMonthDifference: boolean;
  from: string;
  to: string;
}

interface State {
  errorFrom: string;
  errorTo: string;
  valueFrom: string;
  valueTo: string;
}

export default class MonthPicker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      errorFrom: "",
      errorTo: "",
      valueFrom: props.from,
      valueTo: props.to,
    };
  }

  resetErrors = () => {
    this.setState({ errorTo: "" });
    this.setState({ errorFrom: "" });
  };

  fromChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const str = event.target.value;

    this.setState({ valueFrom: str });

    if (moment(str).isValid()) this.props.onChange(str, this.state.valueTo);
  };

  toChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const str = event.target.value;

    this.setState({ valueTo: str });

    if (moment(str).isValid()) this.props.onChange(this.state.valueFrom, str);
  };

  render() {
    return (
      <div className="column is-two-thirds">
        <div className="field is-horizontal month-picker">
          <div className="field-label is-normal">
            <label className="label">Van</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input
                  name="from"
                  className={classNames(
                    "input",
                    this.state.errorFrom ? "is-danger" : ""
                  )}
                  type="month"
                  value={this.state.valueFrom}
                  onChange={this.fromChanged}
                />
              </div>
              <p className="help is-danger">{this.state.errorFrom}</p>
            </div>
          </div>
          <div
            className="field-label is-normal"
            style={{ textAlign: "center" }}
          >
            <label className="label">/</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input
                  name="to"
                  className={classNames(
                    "input",
                    this.state.errorTo ? "is-danger" : ""
                  )}
                  type="month"
                  value={this.state.valueTo}
                  onChange={this.toChanged}
                />
              </div>
              <p className="help is-danger">{this.state.errorTo}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
