import DashboardComponent from "../components/DashboardComponent/DashboardComponent";
import {
  Apotheek,
  Ap304BestandTotal,
  Ap304BestandProgress,
  ApotheekAp304,
} from "../types/model";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../types/state";
import React, { ChangeEvent } from "react";
import { Column, RowInfo, Filter, SortingRule } from "react-table";
import history from "../components/history";
import matchSorter from "match-sorter";
import {
  getApothekenap304,
  setSelectedApotheken,
} from "../actions/apotheekActions";
import {
  getAp304BestandTotal,
  getAp304BestandProgress,
} from "../actions/ap304Actions";
import { setFilterDate } from "../actions/filterActions";
import { setFilterSettings } from "../actions/filterSettingsAction"
import { setIsLoading } from "../actions/gridviewActions";
import { setSortingSettings } from "../actions/sortingSettingsAction";

interface DispatchProps {
  setFilterDate: (from: string, to: string) => any;
  setFilterSettings: (filterSettings: Filter[]) => any;
  setSortingSettings: (sortingsSettings: SortingRule[]) => any;
  getApothekenap304: (
    selectedApotheekIds: number[],
    from: string,
    to: string
  ) => any;
  getAp304BestandTotal: (
    selectedApotheken: number[],
    from: string,
    to: string
  ) => any;
  setSelectedApotheken: (payload: Apotheek[]) => any;
  getAp304BestandProgress: (
    selectedApotheken: number[],
    from: string,
    to: string,
    useIsLoading: boolean
  ) => any;
  setIsLoading: (isLoading: boolean) => any;
}

interface StateProps {
  selectedApotheken: Apotheek[];
  apotheekAp304: ApotheekAp304[];
  ap304BestandTotal: Ap304BestandTotal;
  from: string;
  to: string;
  ap304BestandProgress: Ap304BestandProgress;
  isLoading: boolean;
  filterSettings: Filter[];
  sortingSettings: SortingRule[];
}


class DashboardContainer extends React.Component<DispatchProps & StateProps, RootState> {

  onChange = (from: string, to: string) => {
    this.props.setFilterDate(from, to);

    this.props.getApothekenap304(
      this.props.selectedApotheken.map((a) => a.id),
      from,
      to
    );
    this.props.getAp304BestandProgress(
      this.props.selectedApotheken.map((a) => a.id),
      from,
      to,
      false
    );
    this.props.getAp304BestandTotal(
      this.props.selectedApotheken.map((a) => a.id),
      from,
      to
    );
  };

  onRowClick = (event: ChangeEvent, rowInfo: RowInfo) => {
    let _selectedApotheken = [] as Apotheek[];
    let _apotheek = {
      id: rowInfo.original.id,
      naam: rowInfo.original.apotheeknaam,
    } as Apotheek;

    _selectedApotheken.push(_apotheek);
    this.props.setSelectedApotheken(_selectedApotheken);
    history.push("/Facturen");
  };

  onSortingChanged = (column: string, desc: boolean) => {
    var sorting = this.props.sortingSettings;
    if(!sorting)
    {
      sorting = [] as SortingRule[];
    }

    var sort = sorting.find((x) => {
      return x.id === column;
    });

    if(!sort){
      sort = {id: column, desc: desc} as SortingRule;
      this.props.setSortingSettings([...sorting, sort]);
    }
    else{
      sorting[sorting.indexOf(sort)].desc = desc;
      this.props.setSortingSettings([...sorting]);
    }

  }

  onFilteredChanged = (key: string, value: any) => {
    var settings = this.props.filterSettings;

    if(!settings){
      settings = [] as Filter[];
    }

    var setting = settings.find((x) => {
      return x.id === key;
    });

    if(!setting){
      setting = {id: key, value: value} as Filter;
      this.props.setFilterSettings([...settings, setting]);
    }
    else{
      settings[settings.indexOf(setting)].value = value;
      this.props.setFilterSettings([...settings]);
    }
  };

  componentDidMount() {
    this.props.getApothekenap304(
      this.props.selectedApotheken.map((a) => a.id),
      this.props.from,
      this.props.to
    );
    this.props.getAp304BestandTotal(
      this.props.selectedApotheken.map((a) => a.id),
      this.props.from,
      this.props.to
    );
    this.props.getAp304BestandProgress(
      this.props.selectedApotheken.map((a) => a.id),
      this.props.from,
      this.props.to,
      false
    );
  }

  componentDidUpdate = (prevProps: DispatchProps & StateProps) => {
    if (prevProps.apotheekAp304 !== this.props.apotheekAp304) {
      this.props.setSelectedApotheken(
        this.props.apotheekAp304.map((apotheek) => {
          return { id: apotheek.id, naam: apotheek.apotheeknaam };
        })
      );
    }
  };

  render() {
    const {
      apotheekAp304,
      ap304BestandTotal,
      from,
      to,
      ap304BestandProgress,
      isLoading,
    } = this.props;

    let columns: Array<Column> = [
      {
        Header: "Apotheeknaam",
        accessor: "apotheeknaam",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true,
        Footer: <b>Totaal</b>,
      },
      {
        Header: "Ingediend bedrag",
        accessor: "ingediendBedrag",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true,
        Cell: ({ original }) =>
          new Intl.NumberFormat("nl-NL", {
            style: "currency",
            currency: "EUR",
          }).format(original.ingediendBedrag),
        Footer: (
          <b>
            {new Intl.NumberFormat("nl-NL", {
              style: "currency",
              currency: "EUR",
            }).format(
              apotheekAp304.reduce(
                (total, { ingediendBedrag }) => (total += ingediendBedrag),
                0
              )
            )}
          </b>
        ),
      },
      {
        Header: "TRINGL bedrag",
        accessor: "tringlBedrag",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true,
        Cell: ({ original }) =>
          new Intl.NumberFormat("nl-NL", {
            style: "currency",
            currency: "EUR",
          }).format(original.tringlBedrag),
        Footer: (
          <b>
            {new Intl.NumberFormat("nl-NL", {
              style: "currency",
              currency: "EUR",
            }).format(
              apotheekAp304.reduce(
                (total, { tringlBedrag }) => (total += tringlBedrag),
                0
              )
            )}
          </b>
        ),
      },
      {
        Header: "Uitgekeerd bedrag",
        accessor: "uitgekeerdBedrag",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true,
        Cell: ({ original }) =>
          original.uitgekeerdBedrag !== 0 ? (
            new Intl.NumberFormat("nl-NL", {
              style: "currency",
              currency: "EUR",
            }).format(original.uitgekeerdBedrag)
          ) : (
            <span>-</span>
          ),
        Footer: () => {
          const total = apotheekAp304.reduce(
            (total, { uitgekeerdBedrag }) => (total += uitgekeerdBedrag),
            0
          );

          return total === 0 ? (
            "-"
          ) : (
            <b>
              {new Intl.NumberFormat("nl-NL", {
                style: "currency",
                currency: "EUR",
              }).format(total)}
            </b>
          );
        },
      },
      {
        Header: "Prijsverschil",
        accessor: "prijsVerschil",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true,
        Cell: ({ original }) =>
          original.prijsVerschil !== 0 ? (
            original.prijsVerschil >= 0 ? (
              <span className="positief">
                {new Intl.NumberFormat("nl-NL", {
                  style: "currency",
                  currency: "EUR",
                }).format(original.prijsVerschil * -1)}
              </span>
            ) : (
              <span className="negatief">
                {new Intl.NumberFormat("nl-NL", {
                  style: "currency",
                  currency: "EUR",
                }).format(original.prijsVerschil * -1)}
              </span>
            )
          ) : (
            <span>-</span>
          ),
        //Cell: ({ original }) => new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(original.prijsVerschil)
        Footer: () => {
          let x = apotheekAp304.reduce(
            (total, { prijsVerschil }) => (total += prijsVerschil),
            0
          );
          return (
            <b>
              {x !== 0 ? (
                x >= 0 ? (
                  <span className="positief">
                    {new Intl.NumberFormat("nl-NL", {
                      style: "currency",
                      currency: "EUR",
                    }).format(x * -1)}
                  </span>
                ) : (
                  <span className="negatief">
                    {new Intl.NumberFormat("nl-NL", {
                      style: "currency",
                      currency: "EUR",
                    }).format(x * -1)}
                  </span>
                )
              ) : (
                <span>-</span>
              )}
            </b>
          );
        },
      },
      {
        Header: "Afkeur bedrag",
        accessor: "afkeurBedrag",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true,
        Cell: ({ original }) =>
          new Intl.NumberFormat("nl-NL", {
            style: "currency",
            currency: "EUR",
          }).format(original.afkeurBedrag),
        Footer: (
          <b>
            {new Intl.NumberFormat("nl-NL", {
              style: "currency",
              currency: "EUR",
            }).format(
              apotheekAp304.reduce(
                (total, { afkeurBedrag }) => (total += afkeurBedrag),
                0
              )
            )}
          </b>
        ),
      },
      {
        Header: "Aantal declaratieregels",
        accessor: "aantalDeclaratieregels",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true,
        Footer: (
          <b>
            {apotheekAp304.reduce(
              (total, { aantalDeclaratieregels }) =>
                (total += aantalDeclaratieregels),
              0
            )}
          </b>
        ),
      },
    ];

    return (
      <DashboardComponent
        usePaging={false}
        onChange={this.onChange}
        ap304BestandTotal={ap304BestandTotal}
        onRowClick={this.onRowClick}
        onFilteredChanged={this.onFilteredChanged}
        onSortingChanged={this.onSortingChanged}
        columns={columns}
        apotheekAp304={apotheekAp304}
        ap304BestandProgress={ap304BestandProgress}
        from={from}
        to={to}
        isLoading={isLoading}
        filteredSettings={this.props.filterSettings}
        sortingSettings={this.props.sortingSettings}
      />
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  selectedApotheken: state.selectedApotheken,
  apotheekAp304: state.apotheekAp304,
  ap304BestandTotal: state.ap304BestandTotal,
  from: state.filterDate.from,
  to: state.filterDate.to,
  ap304BestandProgress: state.ap304BestandProgress,
  isLoading: state.isLoading,
  filterSettings: state.filterSettings ? state.filterSettings.filterSettings : [] as Filter[],
  sortingSettings: state.sortingSettings ? state.sortingSettings.sortingSettings : [] as SortingRule[]
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): DispatchProps => ({
  setFilterDate: (from: string, to: string) =>
    dispatch(setFilterDate(from, to)),
  getApothekenap304: (
    selectedApotheekIds: number[],
    from: string,
    to: string
  ) => dispatch(getApothekenap304([], from, to)),
  getAp304BestandTotal: (
    selectedApotheken: number[],
    from: string,
    to: string
  ) => dispatch(getAp304BestandTotal([], from, to)),
  setSelectedApotheken: (payload: Apotheek[]) =>
    dispatch(setSelectedApotheken(payload)),
  getAp304BestandProgress: (
    selectedApotheken: number[],
    from: string,
    to: string,
    useIsLoading: boolean
  ) => dispatch(getAp304BestandProgress([], from, to, useIsLoading)),
  setIsLoading: (isLoading: boolean) => dispatch(setIsLoading(isLoading)),
  setFilterSettings: (filterSettings: Filter[]) => dispatch(setFilterSettings(filterSettings)),
  setSortingSettings: (sortingSettings: SortingRule[]) => dispatch(setSortingSettings(sortingSettings)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
