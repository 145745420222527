import ApiClient from './ApiClient';

import { Apotheek, ApotheekAp304 } from "../types/model";

const endpoint = 'apotheek';

export default class ApotheekApi {
    public static async getApotheken(): Promise<Apotheek[]> {
        var apotheken = await ApiClient.doHttpRequest<Apotheek[]>('get', `${endpoint}/apotheken`);

        return apotheken;
    }

    public static async getApothekenap304(selectedApotheekIds: number[], from: string, to: string): Promise<ApotheekAp304[]> {
        var apotheken = await ApiClient.doHttpRequest<ApotheekAp304[]>(
            'post',
            `${endpoint}/apothekenap304`,
            { selectedApotheekIds, from, to }
        );

        return apotheken;
    }
}