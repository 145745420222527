import React from "react";
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import required from '../../validators/required';
import email from '../../validators/email';
import { TextInput } from "../TextInput";
import imageTringl from '../../images/TringlLogo79.png'
import backImage from '../../images/login.png';

export interface LoginFormProps {
    userName: string;
    password: string;
}

const LoginFormComponent: React.FunctionComponent<InjectedFormProps<LoginFormProps>> = (props) => {
    const { handleSubmit } = props;
    return (
        <div id="login" className="columns is-centered">
            <div id="panel" className="column is-one-third">
                <form onSubmit={handleSubmit}>
                    <div className="columns is-centered is-multiline is-mobile">
                        <div className="column is-full">
                            <div className="column is-two-thirds" id="header">
                                <img src={imageTringl} alt="tringle"  />
                            </div>
                        </div>
                        <div className="column is-full">
                            <div className="column is-two-thirds">
                                <Field
                                    component={TextInput}
                                    type="text"
                                    name="userName"
                                    className="input"
                                    placeholder="Gebruikersnaam"
                                    showSideBySide={false}
                                    validate={[required, email]}
                                />
                                <Field
                                    component={TextInput}
                                    type="password"
                                    name="password"
                                    className="input"
                                    placeholder="Wachtwoord"
                                    showSideBySide={false}
                                    validate={required}
                                />
                            </div>
                        </div>

                        <div className="column is-full">
                            <div className="column is-two-thirds">
                                <button type="submit" className="button loginButton">Login</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="column is-one-third">
                <img src={backImage} alt="No img" />
            </div>
        </div>
    );
}

export default reduxForm<LoginFormProps>({
    form: 'LoginForm'
})(LoginFormComponent);