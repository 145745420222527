import { Action } from "redux";
import { IncontinentieProfiel } from "../types/model";

export const GET_INCONTINENTIEPROFIELEN = "GET_INCONTINENTIEPROFIELEN";
export const GET_INCONTINENTIEPROFIEL = "GET_INCONTINENTIEPROFIEL";

export interface GetIncontinentieProfielenAction extends Action<string> {
    incontinentieProfielen: IncontinentieProfiel[];
}

export interface GetIncontinentieProfielAction extends Action<string> {
    incontinentieProfiel: IncontinentieProfiel;
}

