import * as types from "../constants/incontinentieProfielActionTypes";
import { IncontinentieProfiel } from "../types/model";

const initialStateProfiles: Array<IncontinentieProfiel> = [];
const initialStateProfile: IncontinentieProfiel = {} as IncontinentieProfiel;

type StateProfiles = typeof initialStateProfiles;
type StateProfile = typeof initialStateProfile

export const incontinentieprofielenReducer = (
    state: StateProfiles = initialStateProfiles,
    action: types.GetIncontinentieProfielenAction
) => {
    if (action.type === types.GET_INCONTINENTIEPROFIELEN)
        return action.incontinentieProfielen;

    return state;
};

export const incontinentieprofielReducer = (
    state: StateProfile = initialStateProfile,
    action: types.GetIncontinentieProfielAction
) => {
    if (action.type === types.GET_INCONTINENTIEPROFIEL)
        return action.incontinentieProfiel;

    return state;
};


