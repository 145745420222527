import { setFilterDate } from "../actions/filterActions";
import { getHerdeclaraties } from "../actions/prestatieActions";
import React, { ChangeEvent } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../types/state";
import { Column, RowInfo, Filter, SortingRule } from "react-table";
import { Factuur, Apotheek } from "../types/model";
import history from "../components/history";
import moment from "moment";
import matchSorter from "match-sorter";
import { setIsLoading } from "../actions/gridviewActions";
import { setFilterSettings } from "../actions/filterSettingsAction";
import { setSortingSettings } from "../actions/sortingSettingsAction";
import HerdeclaratiesComponent from "../components/HerdeclaratiesComponent/HerdeclaratiesComponent";

interface DispatchProps {
  setFilterDate: (from: string, to: string) => any;
  getHerdeclaraties: (selectedApotheken: number[]) => any;
  setIsLoading: (isLoading: boolean) => any;
  setFilterSettings: (filterSettings: Filter[]) => any;
  setSortingSettings: (sortingSettings: SortingRule[]) => any;
}

interface StateProps {
  selectedApotheken: Apotheek[];
  from: string;
  to: string;
  selectedFactuur: string;
  facturen: Factuur[];
  isLoading: boolean;
  filterSettings: Filter[];
  sortingSettings: SortingRule[];
}

class HerdeclaratiesContainer extends React.Component<
  DispatchProps & StateProps,
  RootState
> {
  onChange = (from: string, to: string) => {
    this.props.getHerdeclaraties(this.props.selectedApotheken.map((a) => a.id));
  };

  onRowClick = (event: ChangeEvent, rowInfo: RowInfo) => {
    history.push("/Detail", rowInfo.original.id);
  };

  onBackClick = (event: MouseEvent) => {
    history.push("/Facturen");
  };

  onFilteredChanged = (key: string, value: any) => {
    if (key === "factuurNummer") return;

    if (key === "clear" && value === "clear") {
      this.props.setFilterSettings([]);
      return;
    }    

    var settings = this.props.filterSettings;

    if (!settings) {
      settings = [] as Filter[];
    }

    var setting = settings.find((x) => {
      return x.id === key;
    });

    if (!setting) {
      setting = { id: key, value: value } as Filter;
      this.props.setFilterSettings([...settings, setting]);
    } else {
      settings[settings.indexOf(setting)].value = value;
      this.props.setFilterSettings([...settings]);
    }
  };

  onSortingChanged = (column: string, desc: boolean) => {
    var sorting = this.props.sortingSettings;
    if (!sorting) {
      sorting = [] as SortingRule[];
    }

    var sort = sorting.find((x) => {
      return x.id === column;
    });

    if (!sort) {
      sort = { id: column, desc: desc } as SortingRule;
      this.props.setSortingSettings([...sorting, sort]);
    } else {
      sorting[sorting.indexOf(sort)].desc = desc;
      this.props.setSortingSettings([...sorting]);
    }
  };

  componentDidMount() {
    this.props.getHerdeclaraties(this.props.selectedApotheken.map((a) => a.id));
  }

  render() {
    const { facturen, isLoading } = this.props;

    let columns: Array<Column> = [
      {
        Header: "UZOVI",
        accessor: "uzovi",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true,
      },
      {
        Header: "ZI-nummer",
        accessor: "ziNummer",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true,
      },
      {
        Header: "BSN",
        accessor: "bsnNummer",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true,
      },
      {
        Header: "Receptnummer",
        accessor: "receptNummer",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true,
      },
      {
        Header: "Afleverdatum",
        accessor: "afleverDatum",
        headerStyle: {
          textAlign: "left",
        },
        Cell: (props) => moment(props.value).format("DD-MM-YYYY"),
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true,
      },
      {
        Header: "Ingediend bedrag",
        accessor: "importBedrag",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true,
        Cell: ({ original }) =>
          new Intl.NumberFormat("nl-NL", {
            style: "currency",
            currency: "EUR",
          }).format(original.importBedrag),
      },
      {
        Header: "TRINGL bedrag",
        accessor: "tringlBedrag",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true,
        Cell: ({ original }) =>
          new Intl.NumberFormat("nl-NL", {
            style: "currency",
            currency: "EUR",
          }).format(original.tringlBedrag),
      },
      {
        Header: "Uitgekeerd bedrag",
        accessor: "toegekendBedrag",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true,
        Cell: ({ original }) =>
          original.toegekendBedrag === null
            ? "-"
            : new Intl.NumberFormat("nl-NL", {
                style: "currency",
                currency: "EUR",
              }).format(original.toegekendBedrag),
      },
      {
        Header: "Prijs verschil",
        accessor: "prijsVerschil",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true,
        Cell: ({ original }) =>
          original.prijsVerschil === null || original.prijsVerschil === 0
            ? "-"
            : new Intl.NumberFormat("nl-NL", {
                style: "currency",
                currency: "EUR",
              }).format(original.prijsVerschil * -1),
      },
      {
        Header: "Afkeurreden",
        accessor: "afkeurReden",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true,
      },
    ];

    let filtered = undefined;
    if (history.location.state) {
      filtered = [
        {
          id: "factuurNummer",
          value: history.location.state,
        },
      ];

      this.onFilteredChanged("factuurNummer", history.location.state);
    }

    return (
      <>
        <HerdeclaratiesComponent
          onBackClick={this.onBackClick}
          onRowClick={this.onRowClick}
          onChange={this.onChange}
          onFilteredChanged={this.onFilteredChanged}
          onSortingChanged={this.onSortingChanged}
          filtered={filtered}
          columns={columns}
          facturen={facturen}
          isLoading={isLoading}
          filterSettings={this.props.filterSettings}
          sortingSettings={this.props.sortingSettings}
        />
      </>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  selectedApotheken: state.selectedApotheken,
  from: state.filterDate.from,
  to: state.filterDate.to,
  selectedFactuur: state.selectedFactuur,
  facturen: state.facturen,
  isLoading: state.isLoading,
  filterSettings: state.filterSettings
    ? state.filterSettings.filterSettings
    : ([] as Filter[]),
  sortingSettings: state.sortingSettings
    ? state.sortingSettings.sortingSettings
    : ([] as SortingRule[]),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): DispatchProps => ({
  setFilterDate: (from: string, to: string) =>
    dispatch(setFilterDate(from, to)),
  getHerdeclaraties: (
    selectedApotheken: number[]
  ) =>
    dispatch(
      getHerdeclaraties(selectedApotheken)
    ),
  setIsLoading: (isLoading: boolean) => dispatch(setIsLoading(isLoading)),
  setFilterSettings(filterSettings: Filter[]) {
    dispatch(setFilterSettings(filterSettings));
  },
  setSortingSettings(sortingSettings: SortingRule[]) {
    dispatch(setSortingSettings(sortingSettings));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HerdeclaratiesContainer);
