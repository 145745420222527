import FacturenComponent from "../components/FacturenComponent/FacturenComponent";
import { setFilterDate } from "../actions/filterActions";
import React, { ChangeEvent } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../types/state";
import { Column, RowInfo, Filter, SortingRule } from "react-table";
import { Apotheek, Ap304BestandProgress, ApotheekAp304 } from "../types/model";
import history from "../components/history";
import matchSorter from "match-sorter";
import { getAp304BestandProgress } from "../actions/ap304Actions";
import { setIsLoading } from "../actions/gridviewActions";
import { getApothekenap304 } from "../actions/apotheekActions";
import { setSelectedFactuur } from "../actions/prestatieActions";
import { setFilterSettings } from "../actions/filterSettingsAction";
import { setSortingSettings } from "../actions/sortingSettingsAction";

interface DispatchProps {
  setFilterDate: (from: string, to: string) => any;
  setFilterSettings: (filterSettings: Filter[]) => any;
  setSortingSettings: (sortingSettings: SortingRule[]) => any;
  getApothekenap304: (
    selectedApotheekIds: number[],
    from: string,
    to: string
  ) => any;
  getAp304BestandProgress: (
    selectedApotheken: number[],
    from: string,
    to: string,
    useIsLoading: boolean
  ) => any;
  setIsLoading: (isLoading: boolean) => any;
  setSelectedFactuur: (selectedFactuur: string) => any;
}

interface StateProps {
  selectedApotheken: Apotheek[];
  from: string;
  to: string;
  apotheekAp304: ApotheekAp304[];
  selectedFactuur: string;
  ap304BestandProgress: Ap304BestandProgress;
  isLoading: boolean;
  filterSettings: Filter[];
  sortingSettings: SortingRule[];
}

class FacturenContainer extends React.Component<
  DispatchProps & StateProps,
  RootState
> {
  onChange = (from: string, to: string) => {
    this.props.setFilterDate(from, to);
    this.props.getAp304BestandProgress(
      this.props.selectedApotheken.map((a) => a.id),
      from,
      to,
      true
    );
    //this.props.getApothekenap304(this.props.selectedApotheken.map(a => a.id), from, to);
  };

  onRowClick = (event: ChangeEvent, rowInfo: RowInfo) => {
    this.props.setSelectedFactuur(rowInfo.original.factuurNummer);
    history.push("/FactuurDetail", rowInfo.original.factuurNummer);
  };

  componentWillMount() {
    this.props.getAp304BestandProgress(
      this.props.selectedApotheken.map((a) => a.id),
      this.props.from,
      this.props.to,
      true
    );
    //this.props.getApothekenap304(this.props.selectedApotheken.map(a => a.id), this.props.from, this.props.to);
  }

  onSortingChanged = (column: string, desc: boolean) => {
    var sorting = this.props.sortingSettings;
    if(!sorting)
    {
      sorting = [] as SortingRule[];
    }

    var sort = sorting.find((x) => {
      return x.id === column;
    });

    if(!sort){
      sort = {id: column, desc: desc} as SortingRule;
      this.props.setSortingSettings([...sorting, sort]);
    }
    else{
      sorting[sorting.indexOf(sort)].desc = desc;
      this.props.setSortingSettings([...sorting]);
    }

  }

  onFilteredChanged = (key: string, value: any) => {
    var settings = this.props.filterSettings;

    if(!settings){
      settings = [] as Filter[];
    }

    var setting = settings.find((x) => {
      return x.id === key;
    })

    if(!setting){
      setting = {id: key, value: value} as Filter;
      this.props.setFilterSettings([...settings, setting]);
    }
    else{
      settings[settings.indexOf(setting)].value = value;
      this.props.setFilterSettings([...settings]);
    }
  };

  render() {
    const {
      from,
      to,
      ap304BestandProgress,
      apotheekAp304,
      selectedFactuur,
      isLoading,
      selectedApotheken,
    } = this.props;

    let columns: Array<Column> = [
      {
        Header: "UZOVI",
        accessor: "uzovi",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true,
        Footer: <b>Totaal</b>,
      },
      {
        Header: "Zvz naam",
        accessor: "zorgverzekeraarNaam",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true,
      },
      {
        Header: "Factuurnummer",
        accessor: "factuurNummer",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true,
      },
      {
        Header: "Ingediend bedrag",
        accessor: "importBedrag",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true,
        Cell: ({ original }) =>
          new Intl.NumberFormat("nl-NL", {
            style: "currency",
            currency: "EUR",
          }).format(original.importBedrag),
        Footer: (
          <b>
            {new Intl.NumberFormat("nl-NL", {
              style: "currency",
              currency: "EUR",
            }).format(
              (ap304BestandProgress != null
                ? ap304BestandProgress.ap304Bestanden
                : []
              ).reduce((total, { importBedrag }) => (total += importBedrag), 0)
            )}
          </b>
        ),
      },
      {
        Header: "TRINGL bedrag",
        accessor: "tringlBedrag",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true,
        Cell: ({ original }) =>
          new Intl.NumberFormat("nl-NL", {
            style: "currency",
            currency: "EUR",
          }).format(original.tringlBedrag),
        Footer: (
          <b>
            {new Intl.NumberFormat("nl-NL", {
              style: "currency",
              currency: "EUR",
            }).format(
              (ap304BestandProgress != null
                ? ap304BestandProgress.ap304Bestanden
                : []
              ).reduce((total, { tringlBedrag }) => (total += tringlBedrag), 0)
            )}
          </b>
        ),
      },
      {
        Header: "Uitgekeerd bedrag",
        accessor: "toegekendBedrag",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true,
        Cell: ({ original }) =>
          original.hasAp305Bestand ? (
            new Intl.NumberFormat("nl-NL", {
              style: "currency",
              currency: "EUR",
            }).format(original.toegekendBedrag)
          ) : (
            <span>-</span>
          ),
        Footer: () => {
          if (
            ap304BestandProgress == null ||
            !ap304BestandProgress.ap304Bestanden.some((v) => v.hasAp305Bestand)
          )
            return <b>-</b>;

          return (
            <b>
              {new Intl.NumberFormat("nl-NL", {
                style: "currency",
                currency: "EUR",
              }).format(
                (ap304BestandProgress != null
                  ? ap304BestandProgress.ap304Bestanden
                  : []
                )
                  .filter((value) =>
                    value.hasAp305Bestand ? value : undefined
                  )
                  .reduce(
                    (total, { toegekendBedrag }) => (total += toegekendBedrag),
                    0
                  )
              )}
            </b>
          );
        },
      },
      {
        Header: "Prijsverschil",
        accessor: "afkeurBedrag",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true,
        Cell: ({ original }) =>
          original.hasAp305Bestand ? (
            original.afkeurBedrag >= 0 ? (
              <span className="positief">
                {new Intl.NumberFormat("nl-NL", {
                  style: "currency",
                  currency: "EUR",
                }).format(original.afkeurBedrag * -1)}
              </span>
            ) : (
              <span className="negatief">
                {new Intl.NumberFormat("nl-NL", {
                  style: "currency",
                  currency: "EUR",
                }).format(original.afkeurBedrag * -1)}
              </span>
            )
          ) : (
            <span>-</span>
          ),
        Footer: (data, column) => {
          let x = (
            ap304BestandProgress != null
              ? ap304BestandProgress.ap304Bestanden
              : []
          )
            .filter((value) => {
              return value.hasAp305Bestand ? value : undefined;
            })
            .reduce((total, { afkeurBedrag }) => (total += afkeurBedrag), 0);
          return (
            <b>
              {x !== 0 ? (
                x >= 0 ? (
                  <span className="positief">
                    {new Intl.NumberFormat("nl-NL", {
                      style: "currency",
                      currency: "EUR",
                    }).format(x)}
                  </span>
                ) : (
                  <span className="negatief">
                    {new Intl.NumberFormat("nl-NL", {
                      style: "currency",
                      currency: "EUR",
                    }).format(x)}
                  </span>
                )
              ) : (
                <span>-</span>
              )}
            </b>
          );
        },
      },
      {
        Header: "Ingediende regels",
        accessor: "importRegels",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true,
        Footer: (
          <b>
            {(ap304BestandProgress != null
              ? ap304BestandProgress.ap304Bestanden
              : []
            ).reduce((total, { importRegels }) => (total += importRegels), 0)}
          </b>
        ),
      },
      {
        Header: "Opgestuurde regels",
        accessor: "opgestuurdRegels",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true,
        Footer: (
          <b>
            {(ap304BestandProgress != null
              ? ap304BestandProgress.ap304Bestanden
              : []
            ).reduce(
              (total, { opgestuurdRegels }) => (total += opgestuurdRegels),
              0
            )}
          </b>
        ),
      },
      {
        Header: "Aantal regels afkeur",
        accessor: "afkeurRegels",
        headerStyle: {
          textAlign: "left",
        },
        filterMethod: (filter: Filter, rows: any) =>
          matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true,
        Footer: (
          <b>
            {(ap304BestandProgress != null
              ? ap304BestandProgress.ap304Bestanden
              : []
            ).reduce((total, { afkeurRegels }) => (total += afkeurRegels), 0)}
          </b>
        ),
      },
    ];

    return (
      <>
        <FacturenComponent
          selectedApotheken={selectedApotheken}
          selectedFactuur={selectedFactuur}
          apotheekAp304={apotheekAp304}
          onChange={this.onChange}
          onRowClick={this.onRowClick}
          onFilteredChanged={this.onFilteredChanged}
          onSortingChanged={this.onSortingChanged}
          from={from}
          to={to}
          columns={columns}
          ap304BestandProgress={ap304BestandProgress}
          isLoading={isLoading}
          filterSettings={this.props.filterSettings}
          sortingSettings={this.props.sortingSettings}
        />
      </>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  selectedApotheken: state.selectedApotheken,
  from: state.filterDate.from,
  to: state.filterDate.to,
  selectedFactuur: state.selectedFactuur,
  ap304BestandProgress: state.ap304BestandProgress,
  isLoading: state.isLoading,
  apotheekAp304: state.apotheekAp304,
  filterSettings: state.filterSettings ? state.filterSettings.filterSettings : [] as Filter[],
  sortingSettings: state.sortingSettings ? state.sortingSettings.sortingSettings: [] as SortingRule[]
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): DispatchProps => ({
  setFilterDate: (from: string, to: string) =>
    dispatch(setFilterDate(from, to)),
  getAp304BestandProgress: (
    selectedApotheken: number[],
    from: string,
    to: string,
    useIsLoading: boolean
  ) =>
    dispatch(
      getAp304BestandProgress(selectedApotheken, from, to, useIsLoading)
    ),
  setIsLoading: (isLoading: boolean) => dispatch(setIsLoading(isLoading)),
  setSelectedFactuur: (selectedFactuur: string) =>
    dispatch(setSelectedFactuur(selectedFactuur)),
  getApothekenap304: (
    selectedApotheekIds: number[],
    from: string,
    to: string
  ) => dispatch(getApothekenap304([], from, to)),
  setFilterSettings(filterSettings : Filter[]) {
    dispatch(setFilterSettings(filterSettings))
  },
  setSortingSettings(sortingSettings: SortingRule[]) {
    dispatch(setSortingSettings(sortingSettings))
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FacturenContainer);
