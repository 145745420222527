import React from "react";
import { WrappedFieldProps } from "redux-form";
import classnames from "classnames";

interface Props {
  placeholder?: string;
  label?: string;
  readOnly?: boolean;
  text: string;
  name: string;
  showSideBySide?: boolean;
  customWrapperClass?: string;
  onClick?: () => void;
}

export const LabelInput = (props: WrappedFieldProps & Props) => {
  const { name, label, text, showSideBySide, customWrapperClass, onClick } =
    props;

  let wrapperClass = "";
  if (customWrapperClass) wrapperClass = customWrapperClass;
  else {
    wrapperClass =
      "field column is-horizontal is-marginless is-full-mobile is-full-tablet";

    if (showSideBySide)
      wrapperClass = classnames(wrapperClass, "is-half-desktop ");
    else wrapperClass = classnames(wrapperClass, "is-full-desktop ");
  }

  const content = onClick ? (
    <label
      className={classnames("tringlLabel")}
      style={{ textDecoration: "underline", cursor: "pointer" }}
      onClick={onClick}
    >
      {text}
    </label>
  ) : (
    <label className={classnames("tringlLabel")}>{text}</label>
  );

  return (
    <div className={wrapperClass}>
      {label && (
        <div className="field-label">
          <label className="label tringlLabel is-pulled-left" htmlFor={name}>
            {label}
          </label>
        </div>
      )}
      <div className="field-body">{content}</div>
    </div>
  );
};
