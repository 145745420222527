import React from "react";
import ZorgverzekeraarProfielComponent from "../components/ZorgverzekeraarProfielComponent/ZorgverzekeraarProfielComponent";

class ZorgverzekeraarProfielContainer extends React.Component {

    render() {
        return (
            <>
                <ZorgverzekeraarProfielComponent>
                    
                </ZorgverzekeraarProfielComponent>
            </>
        );
    }
}


export default ZorgverzekeraarProfielContainer;