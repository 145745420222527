import { Action } from "redux";

export const SET_GRIDVIEW = "SET_GRIDVIEW";
export const SET_ISLOADING = "SET_ISLOADING";

export interface SetGridviewAction extends Action<string> {
    gridviewId: string;
    filterId: string;
    value: string;
}

export interface SetIsLoading extends Action<string> {
    isLoading: boolean;
}