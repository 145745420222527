import * as React from 'react';
import Step from './Step';
import { Statistieken } from '../../types/model';

interface ProgressBarProps {
    stepCount: number;
    currentStep: number;
    names: Array<string>;
    statistics: Array<Statistieken>;
}
const ProgressBar: React.SFC<ProgressBarProps> = (props) => {
    const { stepCount, currentStep, names, statistics } = props;
    let steps: Array<JSX.Element> = [];
    for (let i = 1; i <= stepCount; i++) {
        steps.push(
            <Step value={i.toString()} isCurrent={currentStep} key={i} currentStep={i} stepCount={stepCount} name={names[(i - 1)].toString()} statistics={statistics[(i - 1)]} />
        );
    }

    return (
        <div className="steps">
            {steps}
        </div>
    );
};
export default ProgressBar;
