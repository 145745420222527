import React from 'react'
import { Field, reduxForm, InjectedFormProps, Form } from 'redux-form';
import { connect } from "react-redux";
import DatePicker from '../DatePicker'
import moment from 'moment';

interface OwnProps {
    onSubmit: (formValues: any) => void;
    verzekerdeId: number
}

class StopProfileComponent extends React.Component<OwnProps & InjectedFormProps<{} & any, OwnProps>> {
    render() {
        const { handleSubmit, onSubmit } = this.props;

        return (
            <Form onSubmit={handleSubmit(onSubmit)}>
                Profiel is stopgezet op
                <div className="columns">
                    <Field
                        component={DatePicker}
                        name="endDate"
                        showSideBySide={true}
                        required={true}
                    />
                </div>
            </Form>
        )
    }
}

const StopProfileReduxForm = reduxForm<{}, OwnProps>({
    form: "StopProfileForm",
    enableReinitialize: true
})(StopProfileComponent);

export default connect(
    (state: any, props: any) => {
        return {
            initialValues: {
                endDate: moment().format('YYYY-MM-DD'),
                verzekerdeId: state.incontinentieProfiel.id
            },
        }
    }
)(StopProfileReduxForm);
