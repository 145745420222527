import * as types from "../constants/middelenActionTypes";
import { Middel } from "../types/model";

const initialState: Array<Middel> = [];

type State = typeof initialState

export const middelenReducer = (
    state: State = initialState,
    action: types.GetMiddelenAction
) => {
    if (action.type === types.GET_MIDDELEN)
    {
        return action.middelen;
    }

    return state;
};
