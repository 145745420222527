import * as React from "react";
import { Column } from "react-table";
import Gridview from "../Gridview/Gridview";
import { Middel } from "../../types/model";
import MiddelenFilterFormComponent, { FilterFormProps } from "../MiddelenFilterFormComponent/MiddelenFilterFormComponent";

interface OwnProps {
    onFilteredChanged: (filter: string, value: string) => void;
    onSubmitFilters: (formvalues:FilterFormProps) => void;
    columns: Array<Column>;
    middelen: Middel[];
    isLoading: boolean;
}

interface State {
    isFormSubmitted: boolean;
}

export default class MiddelenComponent extends React.Component<OwnProps, State> {
    constructor(props: OwnProps) {
        super(props);

        if(props.middelen && props.middelen.length > 0)
            this.state = {isFormSubmitted:true};
        else
            this.state = {isFormSubmitted:false};
    }

    onSubmit = (formValues: FilterFormProps) => {
        this.setState({ isFormSubmitted:true });
        this.props.onSubmitFilters(formValues);
    }
    
    render() {
        const { columns, middelen, onFilteredChanged, isLoading } = this.props;

        return (
            <>
                <div className="column is-full">
                    <div className="bodyHeader column is-full">
                        <div className="column is-one-third">
                            <h1 className="header">Middelen</h1>
                        </div>
                    </div>
                </div>
                <div className="column is-full whiteBackground">
                    <div className="column is-three-quarters">
                        <MiddelenFilterFormComponent onSubmit={this.onSubmit}/>
                    </div>
                    <div className="body content">
                        {this.state.isFormSubmitted && <Gridview onFilteredChanged={onFilteredChanged} columns={columns} data={middelen} isLoading={isLoading} />}
                    </div>
                </div>
            </>
        );
    }
}