import moment from 'moment';
import React from 'react';
import { Column, Filter } from "react-table";
import matchSorter from "match-sorter";


export const profileHistoryColumns: Array<Column> = [
    {
        Header: "Periode van",
        accessor: "van",
        headerStyle: {
            textAlign: "left"
        },
        Cell: ({ original }) => original ? moment(original.van).format("DD-MM-YYYY") : "",
        filterMethod: (filter: Filter, rows: any) =>
            matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true
    },
    {
        Header: "Periode tot",
        accessor: "tot",
        headerStyle: {
            textAlign: "left"
        },
        Cell: ({ original }) => original.tot ? <span style={{ fontWeight: moment(original.tot).isBefore(moment.now()) ? 'normal' : 800 }}> {original ? moment(original.tot).format("DD-MM-YYYY") : ""}  </span> : <span></span>,
        filterMethod: (filter: Filter, rows: any, test: any) =>
            matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true
    },
    {
        Header: "Verzekeraar",
        accessor: "verzekeraarsNaam",
        headerStyle: {
            textAlign: "left"
        },
        filterMethod: (filter: Filter, rows: any) =>
            matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true
    },
    {
        Header: "Profiel",
        accessor: "verzekeraarsProfiel",
        headerStyle: {
            textAlign: "left"
        },
        filterMethod: (filter: Filter, rows: any) =>
            matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true
    },
    {
        Header: "Gebudgetteerd verbruik",
        accessor: "gebudgetteerdVerbruik",
        headerStyle: {
            textAlign: "left"
        },
        Cell: ({ original }) => new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(original.gebudgetteerdVerbruik),
        filterMethod: (filter: Filter, rows: any) =>
            matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true
    },
    {
        Header: "Werkelijk verbruik",
        accessor: "werkelijkVerbruik",
        headerStyle: {
            textAlign: "left"
        },
        Cell: ({ original }) => new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(original.werkelijkVerbruik),
        filterMethod: (filter: Filter, rows: any) =>
            matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true
    }
]


export const deliveredMaterialColumns: Array<Column> = [
    {
        Header: "Datum",
        accessor: "datum",
        headerStyle: {
            textAlign: "left"
        },
        Cell: ({ original }) => original ? moment(original.datum).format("DD-MM-YYYY") : "",
        filterMethod: (filter: Filter, rows: any) =>
            matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true
    },
    {
        Header: "Geleverde artikel",
        accessor: "artikelNaam",
        headerStyle: {
            textAlign: "left"
        },
        filterMethod: (filter: Filter, rows: any) =>
            matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true
    },
    {
        Header: "Aantal stuks",
        accessor: "artikelAantal",
        headerStyle: {
            textAlign: "left"
        },
        filterMethod: (filter: Filter, rows: any) =>
            matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true
    },
    {
        Header: "Gedeclareerde prijs",
        accessor: "gedeclareerdePrijs",
        headerStyle: {
            textAlign: "left"
        },
        Cell: ({ original }) => new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(original.gedeclareerdePrijs),
        filterMethod: (filter: Filter, rows: any) =>
            matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true
    },

]

export const declarationSubmittedColumns: Array<Column> = [
    {
        Header: "Periode van",
        accessor: "van",
        headerStyle: {
            textAlign: "left"
        },
        Cell: ({ original }) => original ? moment(original.van).format("DD-MM-YYYY") : "",
        filterMethod: (filter: Filter, rows: any) =>
            matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true
    },
    {
        Header: "Periode tot",
        accessor: "tot",
        headerStyle: {
            textAlign: "left"
        },
        Cell: ({ original }) => original ? moment(original.tot).format("DD-MM-YYYY") : "",
        filterMethod: (filter: Filter, rows: any, test: any) =>
            matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true
    },
    {
        Header: "Aantal dagen",
        accessor: "aantalDagen",
        headerStyle: {
            textAlign: "left"
        },
        filterMethod: (filter: Filter, rows: any) =>
            matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true
    },
    {
        Header: "Inco profiel",
        accessor: "verzekeraarsProfiel",
        headerStyle: {
            textAlign: "left"
        },
        filterMethod: (filter: Filter, rows: any) =>
            matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true
    },
    {
        Header: "Gedeclareerde prijs",
        accessor: "gedeclareerdePrijs",
        headerStyle: {
            textAlign: "left"
        },
        Cell: ({ original }) => new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(original.gedeclareerdePrijs),
        filterMethod: (filter: Filter, rows: any) =>
            matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true
    },
    {
        Header: "Uitgekeerde prijs",
        accessor: "uitgekeerdePrijs",
        headerStyle: {
            textAlign: "left"
        },
        Cell: ({ original }) => new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(original.uitgekeerdePrijs),
        filterMethod: (filter: Filter, rows: any) =>
            matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true
    },
    {
        Header: "Vecozo status",
        accessor: "status",
        headerStyle: {
            textAlign: "left"
        },
        filterMethod: (filter: Filter, rows: any) =>
            matchSorter(rows, filter.value, { keys: [filter.id] }),
        filterAll: true
    }
]