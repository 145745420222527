import * as React from "react";
import Picky from "react-picky";
import "react-picky/dist/picky.css"; // Include CSS
import { Apotheek } from "../../types/model";
import { NavLink } from "react-router-dom";
import * as icon from "react-feather";
import image from "../../images/TringlLogo.png";

interface OwnProps {
  apotheken: Apotheek[];
  selectedApotheken: Apotheek[];
  onChange: (selectedApotheken: any) => void;
  onLogout: () => Promise<void>;
  accountName: string;
  isUploadUser: string;
}

const determineActiveLocation = (match: any, location: any) => {
  if (!location) return false;
  const { pathname } = location;
  return pathname === "/";
};

export default class HeaderComponent extends React.Component<OwnProps> {
  render() {
    const {
      apotheken,
      selectedApotheken,
      onChange,
      onLogout,
      accountName,
      isUploadUser,
    } = this.props;
    return (
      <>
        <nav
          className="navbar has-shadow"
          role="navigation"
          aria-label="main navigation"
          style={{ marginBottom: "30px" }}
        >
          <div className="navbar-brand">
            {isUploadUser === "False" && (
              <NavLink className="navbar-item" to="/">
                <img className="tringlLogo" alt="logo" src={image} />
              </NavLink>
            )}
            {isUploadUser === "True" && (
              <NavLink className="navbar-item" to="/Upload">
                <img className="tringlLogo" alt="logo" src={image} />
              </NavLink>
            )}
          </div>
          <div id="navbar" className="navbar-menu">
            <div className="navbar-start">
              {isUploadUser === "False" && (
                <>
                  <NavLink
                    className="navbar-item"
                    to="/"
                    activeClassName="is-activemenu"
                    isActive={determineActiveLocation}
                  >
                    Home
                  </NavLink>
                  <NavLink
                    className="navbar-item"
                    to="/Facturen"
                    activeClassName="is-activemenu"
                  >
                    Facturen
                  </NavLink>
                  <NavLink
                    className="navbar-item"
                    to="/Patienten"
                    activeClassName="is-activemenu"
                  >
                    Patienten
                  </NavLink>
                  <NavLink
                    className="navbar-item"
                    to="/Middelen"
                    activeClassName="is-activemenu"
                    onClick={(event) => {
                      this.forceUpdate();
                    }}
                  >
                    Middelen
                  </NavLink>
                  <NavLink
                    className="navbar-item"
                    to="/Herdeclaraties"
                    activeClassName="is-activemenu"
                    onClick={(event) => {
                      this.forceUpdate();
                    }}
                  >
                    Herdeclaraties
                  </NavLink>
                  {/* <NavLink className="navbar-item" to="/InconinentieProfielen" activeClassName="is-activemenu" >
                                        Incontinentie profiel
                                    </NavLink> */}
                </>
              )}
              {/* <NavLink className="navbar-item" to="/ZorgverzekeraarProfiel" activeClassName="is-activemenu" onClick={((event) => {this.forceUpdate()})}>
                                Zorgverzekeraar
                            </NavLink> */}
              <NavLink
                className="navbar-item"
                to="/Upload"
                activeClassName="is-activemenu"
                onClick={(event) => {
                  this.forceUpdate();
                }}
              >
                Upload
              </NavLink>
            </div>

            <div className="navbar-end">
              {isUploadUser === "False" && (
                <>
                  <div className="navbar-item">
                    <Picky
                      options={apotheken}
                      numberDisplayed={1}
                      value={selectedApotheken}
                      valueKey="id"
                      labelKey="naam"
                      multiple={true}
                      includeSelectAll={true}
                      onChange={onChange}
                      dropdownHeight={600}
                    />
                  </div>
                  <NavLink
                    className="navbar-item"
                    to="/Acties"
                    activeClassName="is-activebell"
                  >
                    <icon.Bell />
                  </NavLink>
                  <div className="navbar-item">{accountName}</div>
                </>
              )}
              <div className="navbar-item">
                <div className="buttons">
                  <button className="button is-light" onClick={onLogout}>
                    <i className="fas fa-sign-out-alt"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </>
    );
  }
}
