import * as React from "react";

export default class ZorgverzekeraarProfielComponent extends React.Component {
    render() {

        return (
            <>
                <div className="column is-full">
                    <div className="bodyHeader column is-full">
                        <div className="column is-one-third">
                            <h1 className="header">Zorgverzekeraar Profielen</h1>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}