import * as types from "../constants/sortingSettingTypes";
import {SortingRule} from "react-table"

const sortingSettings = [] as SortingRule[]
const initialState = { sortingSettings: sortingSettings }

type State = typeof initialState

export const setSortingSettingsReducer = (
    state: State = initialState,
    action: types.SetSortingSettingAction
) => {
    if (action.type === types.SET_SORTING_SETTING)
        return { ...state, sortingSettings: action.sortingSettings};

    return state;
};