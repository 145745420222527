import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import classnames from 'classnames';

interface Props {
    placeholder?: string;
    label?: string;
    readOnly?: boolean
    type?: string;
    showSideBySide?: boolean;
    required?: boolean;
    dateFormat?: string
}

export default class DatePicker extends React.Component<WrappedFieldProps & Props> {
    onChange = (e: any) => {
        this.props.input.onChange(e.currentTarget.value);
    }

    render() {
        const { input, label, required, meta: { touched, error }, showSideBySide } = this.props;

        let style = {};

        let wrapperClass = "field column is-horizontal is-marginless is-full-mobile is-full-tablet"

        if (showSideBySide)
            wrapperClass = classnames(wrapperClass, "is-half-desktop");
        else
            wrapperClass = classnames(wrapperClass, "is-full-desktop");


        return (
            <div className={wrapperClass}>
                {label && <div className="field-label"><label className="label is-pulled-left" htmlFor={input.name} style={style}>{label} {required && <span style={{ color: "red" }}>*</span>}</label></div>}
                <div className="field-body">
                    <input
                        name="from"
                        className={"input"}
                        type="date"
                        value={input.value}
                        onChange={(e) => this.onChange(e)}
                    />
                    {touched && error && <p className="help is-danger">{error}</p>}
                </div>
            </div>
        );
    }
}