import * as actionTypes from "../constants/prestatieActionTypes";
import PrestatieApi from '../api/PrestatieApi';
import { GetPrestatiesForFacturenAction, GetPrestatiesForPatientenAction, GetPrestatiesForMiddelenAction, GetPrestatiesForActiesAction, GetPrestatieForDeclaratieRegelAction, SetSelectedFactuurAction } from "../constants/prestatieActionTypes";
import { AsyncAction } from ".";
import { DeclaratieRegel } from "../types/model";
import { setIsLoading } from "./gridviewActions";

export const getPrestatiesForFacturen = (selectedApotheken: number[], from: string, to: string, factuurNummer: string): AsyncAction<GetPrestatiesForFacturenAction> => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        const facturen = 
            await PrestatieApi.getPrestatiesForFacturen(selectedApotheken, from, to, factuurNummer)
                .finally(() => {
                    dispatch(setIsLoading(false));
            });
        

        return dispatch({ type: actionTypes.GET_PRESTATIES_FOR_FACTUREN, facturen });
    };
}

export const getHerdeclaraties = (selectedApotheken: number[]): AsyncAction<GetPrestatiesForFacturenAction> => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        const facturen = 
            await PrestatieApi.getHerdeclaraties(selectedApotheken)
                .finally(() => {
                    dispatch(setIsLoading(false));
            });
        

        return dispatch({ type: actionTypes.GET_HERDECLARATIES, facturen });
    };
}

export const setSelectedFactuur = (selectedFactuur:string): AsyncAction<SetSelectedFactuurAction> => {
    return async (dispatch) => {
        return dispatch({type: actionTypes.SET_SELECTED_FACTUUR, selectedFactuur });
    };
}

export const getPrestatiesForPatienten = (selectedApotheken: number[], from: string, to: string, bsnnummer?: number, naam?: string, geboortedatum?: string): AsyncAction<GetPrestatiesForPatientenAction> => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        
        const patienten = await PrestatieApi.getPrestatiesForPatienten(selectedApotheken, from, to, bsnnummer, naam, geboortedatum)
        .finally(() => {
            dispatch(setIsLoading(false));
        });

        return dispatch({ type: actionTypes.GET_PRESTATIES_FOR_PATIENTEN, patienten });
    };
}

export const getPrestatiesForMiddelen = (selectedApotheken: number[], from: string, to: string): AsyncAction<GetPrestatiesForMiddelenAction> => {
    return async (dispatch) => {
        const middelen = await PrestatieApi.getPrestatiesForMiddelen(selectedApotheken, from, to);

        return dispatch({ type: actionTypes.GET_PRESTATIES_FOR_MIDDELEN, middelen });
    };
}

export const getPrestatiesForActies = (selectedApotheken: number[], from: string, to: string): AsyncAction<GetPrestatiesForActiesAction> => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));

        const acties = await PrestatieApi.getPrestatiesForActies(selectedApotheken, from, to)
        .finally(() => {
            dispatch(setIsLoading(false));
        });
        

        return dispatch({ type: actionTypes.GET_PRESTATIES_FOR_ACTIES, acties });
    };
}

export const getPrestatieForDeclaratieRegel = (selectedApotheken: number[], id: number): AsyncAction<GetPrestatieForDeclaratieRegelAction> => {
    return async (dispatch) => {
        dispatch({ type: actionTypes.GET_PRESTATIE_FOR_DECLARATIE_REGEL, declaratieRegel: {} as DeclaratieRegel });

        const declaratieRegel = await PrestatieApi.getPrestatieForDeclaratieRegel(selectedApotheken, id);

        return dispatch({ type: actionTypes.GET_PRESTATIE_FOR_DECLARATIE_REGEL, declaratieRegel });
    }
}