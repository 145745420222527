import * as React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import { declarationSubmittedColumns, deliveredMaterialColumns, profileHistoryColumns } from './IncontinentieProfileTables'
import Gridview from "../Gridview/Gridview";

import { IncontinentieProfiel, ZorgverzekeraarProfiel } from "../../types/model";
import MonthPicker from '../MonthPicker';
import moment from "moment";
import classNames from "classnames";
import { Modal } from '../Modal';
import StopProfileComponent from '../StopProfile/StopProfileComponent';

interface OwnProps {
    from: string,
    to: string,
    submitForm: (form: string) => any;
    onChangeDate: (from: string, to: string) => void;
    setIncontinenceTabIndex: (index: number) => void;
    addVerzekerdeZvZProfiel: (profielId: number, verzekerdeId: number, startDatum: string) => void
    stopVerzekerdeZvZProfiel: (verzekerdeId: number, eindDatum: string) => void
    incontinenceTabIndex: number;
    incontinentieProfiel: IncontinentieProfiel;
}

interface State {
    startDate: string | undefined;
    healthInsureProfileId: number | undefined;
    showModal: boolean,
}

export default class IncontinentieProfielComponent extends React.Component<OwnProps, State> {
    constructor(props: OwnProps) {
        super(props);
        this.state = {
            healthInsureProfileId: undefined,
            startDate: undefined,
            showModal: false
        }
    }

    componentDidMount = () => {
        this.props.onChangeDate(moment.utc().subtract(1, 'year').format("YYYY-MM"),moment.utc().format("YYYY-MM"))
    }

    changeHealthInsurersProfile = () => {
        const { startDate, healthInsureProfileId } = this.state;
        if (healthInsureProfileId && startDate) {
            this.props.addVerzekerdeZvZProfiel(healthInsureProfileId, this.props.incontinentieProfiel.id, moment(startDate).format("DD-MM-YYYY"))
        }
        this.setState({ startDate: undefined })
    }

    toggleModal = (open: boolean) => {
        this.setState({ showModal: open ? true : false });
    }

    handleChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ startDate: e.currentTarget.value })
    }

    renderAvailableHealthInsurers(healthInsurers: ZorgverzekeraarProfiel[], selectedHealthInsurerId: number | undefined) {
        if (!healthInsurers)
            return;

        var dropdownOptions = healthInsurers.map(hi => <option key={hi.id} value={hi.id}>{hi.profielNummer + " - " + hi.omschrijving}</option>)
        return (
            <div className="select">
                <select onChange={(e) => this.setState({ healthInsureProfileId: parseInt(e.target.value) })} value={this.state.healthInsureProfileId === undefined ? selectedHealthInsurerId : this.state.healthInsureProfileId}>
                    <option value={undefined}>Kies je profiel</option>
                    {dropdownOptions}
                </select>
            </div>
        )
    }

    stopHealthInsurersProfile = (formValues: any) => {
        this.props.stopVerzekerdeZvZProfiel(formValues.verzekerdeId, moment(formValues.endDate).format("DD-MM-YYYY"))
        this.toggleModal(false);

    }

    render() {
        const { incontinenceTabIndex, setIncontinenceTabIndex, incontinentieProfiel } = this.props;
        const { startDate, healthInsureProfileId } = this.state;
        const hasProfile = incontinentieProfiel.huidigProfiel !== null;

        var filteredProfielHistorie = incontinentieProfiel.profielHistories ? incontinentieProfiel.profielHistories.filter(e => moment(this.props.from).startOf('month').isSameOrBefore(moment(e.van)) && (e.tot ? moment(this.props.to).endOf('month').isSameOrAfter(moment(e.tot)) : true)) : [];
        var filteredGeleverdeArtikelen = incontinentieProfiel.geleverdeArtikelen ? incontinentieProfiel.geleverdeArtikelen.filter(e => moment(this.props.from).startOf('month').isSameOrBefore(moment(e.datum)) && moment(this.props.to).endOf('month').isSameOrAfter(moment(e.datum))) : [];
        var filteredIngediendeDeclaraties = incontinentieProfiel.ingediendeDeclaraties ? incontinentieProfiel.ingediendeDeclaraties.filter(e => moment(this.props.from).startOf('month').isSameOrBefore(moment(e.van)) && (e.tot ? moment(this.props.to).endOf('month').isSameOrAfter(moment(e.tot)) : true)) : [];
        
        return (
            <>

                {this.state.showModal
                    ? (<Modal
                        title="Profiel stopzetten"
                        content={
                            <StopProfileComponent
                                onSubmit={this.stopHealthInsurersProfile}
                            />
                        }
                        onSubmit={() => this.props.submitForm("StopProfileForm")}
                        onClose={() => this.toggleModal(false)}
                    />
                    )
                    : (null)
                }


                <div className="columns tringlProfileValue">
                    <div className="column is-full">
                        <div className="column is-full">
                            <div className="column is-one-third">
                                <h1 className="header">Incontinentie Profiel</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="columns ">
                    <div className="column is-offset-one-quarter is-half">
                        <div className="columns">
                            <div className="column tringlProfileLabel" style={{ fontSize: '25px', fontWeight: 600 }}>
                                Gebruikers informatie
                            </div>
                        </div>
                        <div className="columns" >
                            <div className="column">
                                <span className="tringlProfileLabel">BSN: </span><span className="tringlProfileValue">{incontinentieProfiel.verzekerdeBsn}</span>
                            </div>
                            <div className="column">
                                <span className="tringlProfileLabel">Naam: </span><span className="tringlProfileValue">{incontinentieProfiel.verzekerdeNaam}</span>
                            </div>
                            <div className="column">
                                <span className="tringlProfileLabel">Geboorte datum: </span><span className="tringlProfileValue">{moment(incontinentieProfiel.verzekerdeGeboortedatum).format('DD-MM-YYYY')}</span>
                            </div>
                        </div>
                        <div className="bottomBorder"></div>
                        <div style={{ height: '40px' }}>
                        </div>

                        <div className="columns">
                            <div className="column tringlProfileLabel" style={{ fontSize: '25px', fontWeight: 600 }}>
                                {hasProfile ? "Profiel" : "Maak profiel aan"}
                            </div>
                            {hasProfile &&
                                <div className="column">
                                    <button onClick={() => this.toggleModal(true)} style={{ float: 'right' }} className="button is-dark">
                                        Profiel stopzetten
                                    </button>
                                </div>
                            }
                        </div>
                        <div className="columns">
                            <div className="column">
                                <table style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <td className="tringlProfileLabel">Zorgverzekeraar</td>
                                            <td className="tringlProfileLabel">Profiel</td>
                                            <td className="tringlProfileLabel">Startdatum</td>
                                            <td></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{incontinentieProfiel.huidigProfiel ? incontinentieProfiel.huidigProfiel.verzekeraarsNaam : incontinentieProfiel.zorgVerzekeraarNaam}</td>
                                            <td>{this.renderAvailableHealthInsurers(incontinentieProfiel.beshikbareZorgverzekeraarProfielen, incontinentieProfiel.huidigProfiel ? incontinentieProfiel.huidigProfiel.verzekeraarsProfielId : this.state.healthInsureProfileId)}</td>
                                            <td style={{ width: '200px' }}>
                                                <input
                                                    name="from"
                                                    className={classNames("input")}
                                                    type="date"
                                                    value={this.state.startDate === undefined ? "" : this.state.startDate}
                                                    onChange={(e) => { this.handleChangeDate(e) }}
                                                />
                                            </td>
                                            <td>
                                                <button disabled={!healthInsureProfileId ||
                                                    // healthInsureProfileId === incontinentieProfiel.huidigProfiel.verzekeraarsProfielId || 
                                                    startDate === undefined}
                                                    style={{ float: 'right' }} className="button is-dark" onClick={() => this.changeHealthInsurersProfile()}>
                                                    {hasProfile ? "Wijzig profiel" : "Maak profiel aan"}
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div>
                        </div>
                        <div className="bottomBorder"></div>
                        <div style={{ height: '40px' }}>
                        </div>

                        <div className="columns">
                            <div className="column tringlProfileLabel" style={{ fontSize: '25px', fontWeight: 600 }}>
                                Historie
                            </div>
                        </div>
                        <MonthPicker
                            maxOneMonthDifference={false}
                            from={this.props.from}
                            to={this.props.to}
                            onChange={this.props.onChangeDate}
                        />
                        <div className="columns">
                            <div className="column">
                                <Tabs selectedIndex={incontinenceTabIndex} onSelect={(tabIndex: number) => setIncontinenceTabIndex(tabIndex)}>
                                    <TabList>
                                        <Tab>Profiel historie</Tab>
                                        <Tab>Geleverd materiaal</Tab>
                                        <Tab>Ingediende declaratie</Tab>
                                    </TabList>
                                    <TabPanel>
                                        <Gridview isLoading={false} onFilteredChanged={() => { }} usePaging={false} columns={profileHistoryColumns} data={filteredProfielHistorie} height="" />
                                    </TabPanel>
                                    <TabPanel>
                                        <Gridview isLoading={false} onFilteredChanged={() => { }} usePaging={false} columns={deliveredMaterialColumns} data={filteredGeleverdeArtikelen} height="" />
                                    </TabPanel>
                                    <TabPanel>
                                        <Gridview isLoading={false} onFilteredChanged={() => { }} usePaging={false} columns={declarationSubmittedColumns} data={filteredIngediendeDeclaraties} height="" />
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </div>
                        <div style={{ height: '40px' }}>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}