import * as React from "react";
import MonthPicker from "../MonthPicker";
import { Column, Filter, SortingRule } from "react-table";
import Gridview from "../Gridview/Gridview";
import { Factuur } from "../../types/model";

interface OwnProps {
    onChange: (from: string, to: string) => void;
    onRowClick?: (event: any, rowInfo: any) => void;
    onBackClick: (event: any) => void;
    onFilteredChanged: (filter: string, value: string) => void;
    onSortingChanged: (column: string, desc: boolean) => void;
    from: string;
    to: string;
    columns: Array<Column>;
    facturen: Factuur[];
    filtered?: Filter[];
    isLoading: boolean;
    filterSettings: Filter[];
    sortingSettings: SortingRule[];
}

export default class FactuurDetailComponent extends React.Component<OwnProps> {
    render() {
        const { onChange, onRowClick, onFilteredChanged, onSortingChanged, onBackClick, filterSettings, sortingSettings, from, to, columns, facturen, filtered, isLoading } = this.props;

        return (
            <>
                <h1 className="header">Factuur detail</h1>
                <div className="content">
                    <div className="contentPanel">
                            
                        <div className="backButton">
                            <button type="submit" onClick={onBackClick} className="button">Terug</button>
                        </div>

                        <MonthPicker 
                            maxOneMonthDifference={true}
                            onChange={onChange} 
                            from={from} 
                            to={to} 
                        />
                        <Gridview onRowClick={onRowClick} filterSettings={filterSettings} sortingSettings={sortingSettings} onFilteredChanged={onFilteredChanged} onSortingChanged={onSortingChanged} columns={columns} data={facturen} isLoading={isLoading} />
                    </div>
                </div>
            </>
        );
    }
}
