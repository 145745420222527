import { UserManager } from "oidc-client-ts";
import { getSettings } from "../types/settings";

class AuthService {
    private userManager!: UserManager;

    initialize() {
        const settings = getSettings();

        this.userManager = new UserManager({
            authority: settings.authority,
            client_id: settings.clientId,
            redirect_uri: settings.redirectUrl,
            post_logout_redirect_uri: settings.redirectUrl,
            scope: settings.scopes
        });
    }

    public async isAuthenticated() {
        const user = await this.userManager.getUser();

        if (user === null)
            return false;

        return !user.expired;
    }

    public async handleRedirect() {
        try {
            const user = await this.userManager.signinRedirectCallback();
            await this.userManager.storeUser(user);

            return true;
        } catch (e) {
            return false;
        }
    }

    public async signIn() {
        return this.userManager.signinRedirect();
    }

    public async signOut() {
        await this.userManager.removeUser();
        await this.userManager.signoutRedirect();
    }

    public async getToken() {
        if (!await this.isAuthenticated()) {
            console.log("not authed");
            
            await this.userManager.signinSilent();
        }

        const user = await this.userManager.getUser();

        return user?.access_token ?? "";
    }
}

export const authService = new AuthService();