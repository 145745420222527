import * as React from "react";
import { Column, Filter, SortingRule } from "react-table";
import Gridview from "../Gridview/Gridview";
import { Factuur } from "../../types/model";

interface OwnProps {
  onChange: (from: string, to: string) => void;
  onRowClick?: (event: any, rowInfo: any) => void;
  onBackClick: (event: any) => void;
  onFilteredChanged: (filter: string, value: string) => void;
  onSortingChanged: (column: string, desc: boolean) => void;
  columns: Array<Column>;
  facturen: Factuur[];
  filtered?: Filter[];
  isLoading: boolean;
  filterSettings: Filter[];
  sortingSettings: SortingRule[];
}

export default class HerdeclaratiesComponent extends React.Component<OwnProps> {
  componentDidMount(): void {
    this.props.onFilteredChanged("clear", "clear");  
  }

  render() {
    const {
      onRowClick,
      onFilteredChanged,
      onSortingChanged,
      filterSettings,
      sortingSettings,
      columns,
      facturen,
      isLoading,
    } = this.props;

    return (
      <>
        <div className="topBar">
          <div>Herdeclaraties</div>
        </div>

        <div className="content">
          <div className="contentPanel">
            <Gridview
              onRowClick={onRowClick}
              filterSettings={filterSettings}
              sortingSettings={sortingSettings}
              onFilteredChanged={onFilteredChanged}
              onSortingChanged={onSortingChanged}
              columns={columns}
              data={facturen}
              isLoading={isLoading}
            />
          </div>
        </div>
      </>
    );
  }
}
