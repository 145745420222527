import React from 'react';

interface Props {
    title: string;
    content: any;
    pdfGenerated?: boolean;
    readOnly?: boolean;
    onSubmit: (formValues: any) => void;
    onClose: () => void;
    onArchive?: () => void;
    height?: string;
    removeScroll?: boolean
    saveButtonIsReadOnly? : boolean;
    buttons?: any;
}

export const Modal = (props: Props) => {
    const { title, buttons, removeScroll, content, readOnly = false, onClose, onSubmit, onArchive, height, saveButtonIsReadOnly } = props;


    return (
        <>
            <div className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-card" style={{ width: "50vw" }}>
                    <header className="modal-card-head">
                        <p className="modal-card-title">{title}</p>
                        <button className="delete" aria-label="close" onClick={onClose}></button>
                    </header> 
                    <section className="modal-card-body" style={{ height: height, overflowX: removeScroll ? 'unset' : 'hidden', overflow : removeScroll ? 'unset' : 'auto' }}>
                        {content}
                    </section>
                    <footer className="modal-card-foot buttons is-right">
                        {!readOnly && buttons ? buttons : ''}
                        {!readOnly && onArchive && <button className="button is-success delete-button" onClick={onArchive}>Verwijderen</button>}
                        <button className="button" onClick={onClose}>{readOnly ? "Terug" : "Annuleren"}</button>
                        {!readOnly && <button disabled={saveButtonIsReadOnly} className="button is-dark" onClick={onSubmit}>Opslaan</button>}
                    </footer>
                </div>
            </div>
        </>
    )
}