import * as React from "react";
import { Column } from "react-table";
import Gridview from "../Gridview/Gridview";
import { Patient } from "../../types/model";
import PatientenFilterFormComponent, {
  FilterFormProps,
} from "../PatientenFilterFormComponent/PatientenFilterFormComponent";

interface OwnProps {
  onChange: (from: string, to: string) => void;
  onSubmitFilters: (formValues: FilterFormProps) => void;
  onRowClick?: (event: any, rowInfo: any) => void;
  onFilteredChanged: (filter: string, value: string) => void;
  from: string;
  to: string;
  columns: Array<Column>;
  patienten: Patient[];
  isLoading: boolean;
}

interface State {
  isFormSubmitted: boolean;
}

export default class PatientenComponent extends React.Component<
  OwnProps,
  State
> {
  constructor(props: OwnProps) {
    super(props);

    if (props.patienten && props.patienten.length > 0)
      this.state = { isFormSubmitted: true };
    else this.state = { isFormSubmitted: false };
  }

  onSubmit = (formValues: FilterFormProps) => {
    this.setState({ isFormSubmitted: true });
    this.props.onSubmitFilters(formValues);
  };

  render() {
    const { columns, patienten, onRowClick, onFilteredChanged, isLoading } =
      this.props;

    return (
      <>
        <div className="column is-full">
          <div className="bodyHeader column is-full">
            <div className="column is-one-third">
              <h1 className="header">Patienten</h1>
            </div>
          </div>
        </div>

        <div className="column is-full whiteBackground">
          <div className="column is-full">
            <PatientenFilterFormComponent onSubmit={this.onSubmit} />
          </div>
          {this.state.isFormSubmitted && (
            <Gridview
              onRowClick={onRowClick}
              onFilteredChanged={onFilteredChanged}
              columns={columns}
              data={patienten}
              isLoading={isLoading}
            />
          )}
        </div>
      </>
    );
  }
}
