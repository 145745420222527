import moment from "moment";
import React from "react";

interface Props {
  label: string;
  value: string;
  onChange: (value: string) => void;
  editable: boolean;
  onClick?: () => void;
}

interface State {
  value: string;
}

export class HerdeclaratieDateField extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      value: props.value
    };
  }
  render() {
    return (
      <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop">
        <div className="field-label">
          <label className="label tringlLabel is-pulled-left">
            {this.props.label}
          </label>
        </div>
        <div
          className="field-body"
          style={
            !this.props.editable && this.props.onClick
              ? { textDecoration: "underline", cursor: "pointer" }
              : undefined
          }
          onClick={!this.props.editable ? this.props.onClick : undefined}
        >
          {this.props.editable ? (
            <input
              type="date"
              onChange={(e) => {
                const newValue = e.target.value;
                this.setState({ value: newValue });

                if (e.target.valueAsDate) {
                  this.props.onChange(newValue);
                }
              }}
              value={this.state.value}
            />
          ) : (
            moment(this.props.value).format("DD-MM-YYYY")
          )}
        </div>
      </div>
    );
  }
}
