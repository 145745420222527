import MiddelenComponent from "../components/MiddelenComponent/MiddelenComponent";
import React from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from '../types/state';
import { Column, Filter } from "react-table";
import { Middel } from "../types/model";
import moment from "moment";
import matchSorter from "match-sorter";
import { getMiddelen } from "../actions/middelActions";
import { setIsLoading } from "../actions/gridviewActions";
import { FilterFormProps } from "../components/MiddelenFilterFormComponent/MiddelenFilterFormComponent";

interface DispatchProps {
    getMiddelen: (taxeMaand?: string, artikelomschrijving?:string, ziNummer?:number) => any;
    setIsLoading: (isLoading:boolean) => any;
}

interface StateProps {
    isLoading: boolean;
    middelen: Middel[];
}

class MiddelenContainer extends React.Component<DispatchProps & StateProps> {
    onFilteredChanged = (filter: string, value: string) => {
        
    }

    onSubmitFilters = (formValues: FilterFormProps) => {
        let taxeMaand = formValues.taxeMaand ? moment(formValues.taxeMaand).format("YYYY-MM") : undefined;

        console.log(formValues.taxeMaand);
        
        this.props.getMiddelen(taxeMaand, formValues.artikelOmschrijving, formValues.ziNummer);
    }

    render() {
        const { middelen, isLoading } = this.props;

        let columns: Array<Column> = [
            {
                Header: "ZI-nummer",
                accessor: "ziNummer",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [filter.id] }),
                filterAll: true
            },
            {
                Header: "Artikelomschrijving",
                accessor: "omschrijving",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [filter.id] }),
                filterAll: true
            },
            {
                Header: "Adviesprijs",
                accessor: "adviesprijs",
                headerStyle: {
                    textAlign: "left"
                },
                
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [filter.id] }),
                filterAll: true,
                Cell: props => new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(props.value)
            },
            {
                Header: "Maand in taxe",
                accessor: "maandInTaxe",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [filter.id] }),
                filterAll: true,
                Cell: props => moment(props.value).format('DD-MM-YYYY')
            }
        ]

        return (
            <>
                <MiddelenComponent onSubmitFilters={this.onSubmitFilters} onFilteredChanged={this.onFilteredChanged} columns={columns} middelen={middelen} isLoading={isLoading} />
            </>
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    middelen: state.middelen,
    isLoading: state.isLoading
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): DispatchProps => ({
    getMiddelen: (taxeMaand?:string, artikelomschrijving?:string, ziNummer?:number) => dispatch(getMiddelen(taxeMaand, artikelomschrijving, ziNummer)),
    setIsLoading: (isLoading:boolean) => dispatch(setIsLoading(isLoading))
});

export default connect(mapStateToProps, mapDispatchToProps)(MiddelenContainer);