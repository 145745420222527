import ApiClient from './ApiClient';

import { Middel } from "../types/model";

const endpoint = 'artikel';

export default class MiddelApi {
    public static async getMiddelen(taxeMaand?:string, artikelOmschrijving?:string, ziNummer?:number): Promise<Middel[]> {
        var middelen = await ApiClient.doHttpRequest<Middel[]>('post', `${endpoint}/artikelen`, { taxeMaand, artikelOmschrijving, ziNummer });

        return middelen;
    }
}