import React from "react";
import "./index.css";
import { RootState, LoginState } from "./types/state";
import { Template } from './components/Template';
import { ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { login } from "./actions";
import { AnyAction } from "redux";

export interface StateProps {
    LoginState: LoginState;
}

interface DispatchProps {
    login: () => any;
}

class App extends React.Component<DispatchProps> {
    constructor(props: DispatchProps) {
        super(props);

        this.props.login();
    }

    render() {
        return (
            <>
                <ToastContainer
                    toastClassName="test"
                    position="top-center"
                    autoClose={3000}
                    closeOnClick
                />
                <Template />
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        LoginState: state.login
    };
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): DispatchProps => ({
    login: () => dispatch(login())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
