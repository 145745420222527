import * as actionTypes from "../constants/incontinentieProfielActionTypes";
import IncontinentieProfielApi from '../api/IncontinentieProfielApi';
import { GetIncontinentieProfielenAction, GetIncontinentieProfielAction } from "../constants/incontinentieProfielActionTypes";
import { AsyncAction } from ".";
import { setIsLoading } from "./gridviewActions";

export const getIncontinentieProfielen = (bsnnummer?: number, naam?: string, geboortedatum?: string) : AsyncAction<GetIncontinentieProfielenAction> =>{
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        
        const incontinentieProfielen = await IncontinentieProfielApi.getIncontinentieProfielen(bsnnummer, naam, geboortedatum)
        .finally(() => {
            dispatch(setIsLoading(false));
        });

        return dispatch({ type: actionTypes.GET_INCONTINENTIEPROFIELEN, incontinentieProfielen });
    };
} 

export const getIncontinentieProfiel = (id: number) : AsyncAction<GetIncontinentieProfielAction> =>{
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        
        const incontinentieProfiel = await IncontinentieProfielApi.getIncontinentieProfiel(id)
        .finally(() => {
            dispatch(setIsLoading(false));
        });

        return dispatch({ type: actionTypes.GET_INCONTINENTIEPROFIEL, incontinentieProfiel });
    };
}

export const addVerzekerdeZvZProfiel = (profielId : number, verzekerdeId :number , startDatum : string) : AsyncAction<any> =>{
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        
        await IncontinentieProfielApi.addVerzekerdeZvZProfiel(profielId, verzekerdeId, startDatum)
        .finally(() => {
            dispatch(setIsLoading(false));
        });
        dispatch(getIncontinentieProfiel(verzekerdeId));
    };
}

export const stopVerzekerdeZvZProfiel = (verzekerdeId :number , eindDatum : string) : AsyncAction<any> =>{
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        
        await IncontinentieProfielApi.stopVerzekerdeZvZProfiel(verzekerdeId, eindDatum)
        .finally(() => {
            dispatch(setIsLoading(false));
        });
        dispatch(getIncontinentieProfiel(verzekerdeId));
    };
}
