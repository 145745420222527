import * as React from "react";
import { Actie, Apotheek, TringlAlert } from "../../types/model";
import * as Icon from "react-feather";

interface OwnProps {
    readonly onActie: (id: number, action: boolean, selectedApotheken: Apotheek[], from: string, to: string) => void;
    acties: Actie[];
    selectedApotheken: Apotheek[]; 
    alerts: TringlAlert[];
    from: string; 
    to: string;
    isLoading: boolean;
}

const actieRow = (alert: TringlAlert, onActie: any, selectedApotheken: Apotheek[], from: string, to: string): JSX.Element => {
    if(alert.prestatie)
    return (
        <div className={"actieRow actieId-" + alert.id}>
            <div className="ingediendBedrag">
                <span className="actieTitle">Ingediend bedrag</span>
                <span className="spanValue">{alert.prestatie.importBedrag}</span>
            </div>
            <div className="uzovi">
                <span className="actieTitle">Uzovi</span>
                <span className="spanValue">{alert.prestatie.uzovi}</span>
            </div>
            <div className="ZI-nummer">
                <span className="actieTitle">ZI-nummer</span>
                <span className="spanValue">{alert.prestatie.ziNummer}</span>
            </div>
            <div className="bsnNummer">
                <span className="actieTitle">BSN nummer</span>
                <span className="spanValue">{alert.prestatie.bsnNummer}</span>
            </div>
            <div className="afleverDatum">
                <span className="actieTitle">Afleverdatum</span>
                <span className="spanValue">{alert.prestatie.afleverDatum}</span>
            </div>            
            <div className="Actie">
                <span className="actieTitle">Actie</span>
                <span className="spanValue">
                    {
                        alert.isOpgelost ? <Icon.X onClick={() => onActie(alert.id, false, selectedApotheken, from, to)} /> : <Icon.Check onClick={() => onActie(alert.id, true, selectedApotheken, from, to)} />
                    }
                </span>
            </div>
            <div className="actieReden">
                <span className="actieTitle">Aflever reden</span>
                <span className="spanValue">{alert.prestatie.retourOmschrijving}</span>
            </div>
        </div>
                
    );
    else
    return (
        <div className={"actieRow actieId-" + alert.id}>
            <div className="meldingOnly">
                <span className="actieTitle">Melding</span>
                <span className="spanValue">{alert.beschrijving}</span>
            </div>          
            <div className="Actie">
                <span className="actieTitle">Actie</span>
                <span className="spanValue">
                    {
                        alert.isOpgelost ? <Icon.X onClick={() => onActie(alert.id, false, selectedApotheken, from, to)} /> : <Icon.Check onClick={() => onActie(alert.id, true, selectedApotheken, from, to)} />
                    }
                </span>
            </div>
        </div>
    );
}


export default class ActiesComponent extends React.Component<OwnProps> {
    render() {
        const { alerts, onActie, selectedApotheken, from, to, isLoading } = this.props;

        const loadingContent = <>  
            <div className={"actieRow"}>
                <div className="ingediendBedrag">
                    <span className="actieTitle"><h5>Loading</h5></span>
                </div>
            </div>
        </>

        return (
            <>
                <div className="column is-full">
                    <div className="bodyHeader column is-full">
                        <div className="column is-one-third">
                            <h1 className="header">Acties</h1>
                        </div>
                    </div>
                </div>
                <div className="column is-full whiteBackground">
                    <div className="body content actieDiv">
                        <h1>Openstaande acties</h1>
                        {isLoading && (
                          loadingContent  
                        )}
                        
                        {!isLoading && (
                            alerts.filter(a => { return a.isOpgelost === false; }).map(a => { return actieRow(a, onActie, selectedApotheken, from, to); })
                        )}

                        <h1>Gesloten acties</h1>
                        {isLoading && (
                          loadingContent  
                        )}

                        {!isLoading && (
                            alerts.filter(a => { return a.isOpgelost === true; }).map(a => { return actieRow(a, onActie, selectedApotheken, from, to); })
                        )}

                    </div>
                </div>
            </>
        );
    }
}