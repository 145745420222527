import * as React from "react";
import classnames from "classnames";

export const MonthInput = (props) => {
  const {
    input,
    label,
    type = "text",
    meta: { touched, error },
    placeholder,
    showSideBySide,
    customWrapperClass,
    readOnly,
  } = props;

  let style = {};

  let wrapperClass = "";

  if (customWrapperClass) wrapperClass = customWrapperClass;
  else {
    wrapperClass =
      "field column is-horizontal is-marginless is-full-mobile is-full-tablet";

    if (showSideBySide)
      wrapperClass = classnames(wrapperClass, "is-half-desktop ");
    else wrapperClass = classnames(wrapperClass, "is-full-desktop ");
  }

  style = { paddingTop: ".5rem", paddingRight: "2rem" };
  return (
    <div className={wrapperClass}>
      {label && (
        <div className="field-label">
          <label
            className="label tringlLabel is-pulled-left"
            htmlFor={input.name}
            style={style}
          >
            {label}
          </label>
        </div>
      )}
      <div className="field-body">
        <input
          {...input}
          className={classnames("input", touched && error && "is-danger")}
          type="month"
        />
        {touched && error && <span className="help is-danger">{error}</span>}
      </div>
    </div>
  );
};
