export interface Ap304BestandProgress {
  ap304Bestanden: Ap304Bestand[];
  importStatistieken: Statistieken;
  tringlStatistieken: Statistieken;
  opgestuurdStatistieken: Statistieken;
  toegekendStatistieken: Statistieken;
}

export interface Statistieken {
  aantalBestanden: number;
  bedrag: number;
  regels: number;
  aantalPatienten: number;
}

export interface Ap304BestandTotal {
  totaalBedrag: number;
  totaalRegels: number;
  aantalPatienten: number;
}

export interface Ap304Bestand {
  id: number;
  uzovi: string;
  zorgverzekeraarNaam: string;
  factuurNummer: string;
  importBedrag: number;
  tringlBedrag: number;
  toegekendBedrag: number;
  afkeurBedrag: number;
  importRegels: number;
  opgestuurdRegels: number;
  afkeurRegels: number;
  hasAp305Bestand: boolean;
}

export interface Apotheek {
  id: number;
  naam: string;
}

export interface ApotheekAp304 {
  id: number;
  apotheeknaam: string;
  ingediendBedrag: number;
  tringlBedrag: number;
  uitgekeerdBedrag: number;
  prijsVerschil: number;
  aantalDeclaratieregels: number;
  afkeurBedrag: number;
}

export interface FileModel {
  fileName: string;
  content: string;
}

export interface Factuur {
  id: number;
  uzovi: string;
  factuurNummer: string;
  ziNummer: string;
  bsnNummer: string;
  receptNummer: string;
  afleverDatum: string;
  importBedrag: number;
  tringlBedrag: number;
  toegekendBedrag: number;
  prijsVerschil: number;
  afkeurReden: string;
}

export interface Patient {
  id: number;
  uzovi: string;
  bsnNummer: string;
  naam: string;
  voorletters: string;
  voorvoegsel: string;
  receptNummer: string;
  ziNummer: string;
  afleverDatum: string;
  importBedrag: number;
  tringlBedrag: number;
  toegekendeBedrag: number;
  afkeurReden: string;
}

// export interface Middel {
//     id: number;
//     uzovi: string;
//     ziNummer: string;
//     artikelOmschrijving: string;
//     aantalGeleverd: number;
//     eenheid: string;
//     afleverDatum: string;
//     importBedrag: number;
//     tringlBedrag: number;
//     toegekendeBedrag: number;
//     afkeurReden: string;
// }

export interface Middel {
  ziNummer: string;
  omschrijving: string;
  adviesprijs: string;
  maandInTaxe: string;
}

export interface ChangePassword {
  changing: boolean;
}

export interface Account {
  voornaam: string;
  achternaam: string;

  wachtwoord?: string;
  wachtwoordBevestigen?: string;
}
export interface DeclaratieRegel {
  id: number;
  
  //Patient
  naam: string;
  bsnNummer: string;
  verzekerdeNummer: string;
  uzovi: string;
  geboorteDatum: string;

  //Betrokkenen
  soortVoorschrijver: number;
  agbVoorschrijver: string;
  soortBehandelaar: number;
  agbBehandelaar: string;
  agbInstelling: string;

  //Declaratie
  receptNummer: string;
  datumLevering: string;
  afkeurReden: string;

  prijsberekeningType: PrijsberekeningType | null;
  referentieArtikelNaam: string | null;

  isHerdeclareerd: boolean;

  regels: {
    id: number;
    aanduiding: number;

    aantalGeleverd: number;
    eenheid: string;
    ziNummer: string;
    artikelOmschrijving: string;

    artikelImportBedrag: number;
    artikelTringlBedrag: number;
    artikelToegekendBedrag: number;
  }[]
}

export interface Actie {
  id: number;
  uzovi: string;
  ziNummer: string;
  bsnNummer: string;
  receptNummer: string;
  afleverDatum: string;
  importBedrag: number;
  actie: string;
  isOpgelost: boolean;
}

export interface TringlAlert {
  id: number;
  prestatie: AlertPrestatie;
  type: string;
  beschrijving: string;
  isOpgelost: boolean;
}

export interface AlertPrestatie {
  id: number;
  uzovi: string;
  ziNummer: string;
  bsnNummer: string;
  receptNummer: string;
  afleverDatum: string;
  importBedrag: number;
  retourOmschrijving: string;
}

export interface IncontinentieProfiel {
  id: number;
  verzekerdeVoorletters: string;
  verzekerdeBsn: string;
  verzekerdeNaam: string;
  verzekerdeGeboortedatum: string;
  zorgVerzekeraarId: string;
  zorgVerzekeraarNaam: string;
  profielHistories: VerzekerdeZvZProfiel[];
  huidigProfiel: VerzekerdeZvZProfiel;
  beshikbareZorgverzekeraarProfielen: ZorgverzekeraarProfiel[];
  geleverdeArtikelen: IncontinentieArtikel[];
  ingediendeDeclaraties: DeclaratieIncontinentieRegel[];
}

export interface IncontinentieArtikel {
  artikelNaam: string;
  artikelAantal: number;
  datum: string;
  gedeclareerdePrijs: number;
}

export interface VerzekerdeZvZProfiel {
  van: string;
  tot: string;
  verzekeraarsProfielId: number;
  verzekeraarsNaam: string;
  verzekeraarsProfiel: string;
  gebudgetteerdVerbruik: number;
  werkelijkVerbruik: number;
}

export interface ZorgverzekeraarProfiel {
  id: number;
  omschrijving: string;
  profielNummer: string;
}

export interface DeclaratieIncontinentieRegel {
  van: string;
  tot: string;
  aantalDagen: number;
  gedeclareerdePrijs: number;
  uitgekeerdePrijs: number;
  verzekeraarsProfiel: string;
  string: number;
}

export enum PrijsberekeningType {
  Geen = 0,
  Bwmg = 1,
  BwmgAflevering = 2,
  Idea = 3,
  LpgBinnen = 4,
  LpgBuiten = 5,
  Overig = 6,
  Preferentiebeleid = 7,
  Uitzondering = 8,
}

export interface CreateHerdeclaratieModel {
  uzovi: string;
  bsnNummer: string;
  soortVoorschrijver: number;
  agbVoorschrijver: string;
  soortBehandelaar: number;
  agbBehandelaar: string;
  agbInstelling: string;
  datumPrestatie: string;
  geboorteDatum: string;
  verzekerdeNummer: string;

  regels: {
    id: number;

    aantalUitgevoerdePrestaties: number;
    prestatiecode1: string;
    prijs: number;
  }[]
}