import * as React from 'react';
import classNames from 'classnames';
import { Statistieken } from '../../types/model';
import * as icons from 'react-feather';
import { MdEuroSymbol } from 'react-icons/md';

interface StepProps {
    value: string;
    isCurrent: number;
    currentStep: number;
    stepCount: number;
    name: string;
    statistics: Statistieken;
}

const Step: React.SFC<StepProps> = (props) => {
    const { value, isCurrent, currentStep, stepCount, name, statistics } = props;
    return (
        <div className="stepContainer" style={{ flexGrow: 1 }}>
            <p className="wizardUpperText">{name}</p>
            <div className={classNames("lineBefore", isCurrent === currentStep - 1 && "isCurrent", currentStep === 1 && "isInvisible")} />
            <div className={classNames("lineAfter", isCurrent === currentStep && "isCurrent", currentStep === stepCount && "isInvisible")} />
            <div className={classNames("step", isCurrent === currentStep && "isCurrent")}>
                <div className="alligner">
                    {value}
                </div>
            </div>
            <div className="wizardUnderText">
                <div className="wizardIconText"><icons.Folder className="stepIcon" /> <span> {statistics.aantalBestanden} bestanden</span></div>
                <div className="wizardIconText"><MdEuroSymbol color={"#00e6d5"} size={24} /><span> 
                    {new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(statistics.bedrag)}
                </span></div>
                <div className="wizardIconText"><icons.List className="stepIcon" /> <span> {statistics.regels} regels</span></div>
             </div>
        </div>
    );
};
export default Step;
