import React, { ChangeEvent } from "react";
import ReactTable, { Column, RowInfo, Filter, SortingRule } from "react-table";
import "react-table/react-table.css";
import "./Gridview.css"

interface Props {
    data: Array<Object>;
    columns: Array<Column>;
    onRowClick?: (event: any, rowInfo: any) => void;
    onFilteredChanged: (filter: string, value: string) => void;
    onSortingChanged: (column: string, desc: boolean)  =>void;
    filterSettings?: Filter[];
    sortingSettings?: SortingRule[];
    height?: string;
    isLoading: boolean;
    usePaging?: boolean;
}

export default class Gridview extends React.Component<Props> {

    render() {
        const { filterSettings, sortingSettings, data, columns, onRowClick, onFilteredChanged, onSortingChanged, isLoading, usePaging = true } = this.props;
        //console.log(filterSettings);
        return (
            <ReactTable
                minRows={data.length < 10 ? data.length : 10}
                pageSize={data.length > 10 ? usePaging ? undefined : data.length : 10}
                data={data}
                columns={columns}
                defaultPageSize={usePaging ? 10 : 100}
                showPagination={usePaging}
                getTdProps={(state: any, rowInfo: RowInfo | undefined, column: Column<any> | undefined, instance: any) => {
                    return {
                        onClick: (e: ChangeEvent) => {
                            if (onRowClick && rowInfo !== undefined && rowInfo.original !== undefined)
                                onRowClick(e, rowInfo);
                        }
                    }
                }}
                onFilteredChange={(filtered, column) => {
                    const filter = filtered.find(f => f.id === column.id);
                    if (filter)
                        onFilteredChanged(filter.id, filter.value);
                    else
                        onFilteredChanged(column.id, '');
                }}
                onSortedChange={(rules, column) => {
                    const sort = rules.find(r => r.id === column.id);
                    if (sort)
                        onSortingChanged(sort.id, sort.desc);
                    else
                        onSortingChanged(column.id, false);
                }}
                loading={isLoading}
                filterable={true}
                className="-striped -highlight"
                defaultFiltered={filterSettings}
                defaultSorted={sortingSettings}
            />
        );
    }
}
