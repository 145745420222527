import { getIncontinentieProfiel, addVerzekerdeZvZProfiel, stopVerzekerdeZvZProfiel } from "../actions/incontinentieProfielActions";
import { setFilterDate } from '../actions/filterActions';
import React from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { submit } from "redux-form";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from '../types/state';
import { IncontinentieProfiel } from "../types/model";
import { withRouter } from "react-router-dom";
import { setIsLoading } from "../actions/gridviewActions";
import IncontinentieProfielComponent from "../components/IncontinentieProfielComponent/IncontinentieProfielComponent";
import { RouteComponentProps } from "react-router";
import moment from "moment";

interface DispatchProps {
    setFilterDate: (from: string, to: string) => any
    getIncontinentieProfiel: (id: number) => any;
    setIsLoading: (isLoading: boolean) => any;
    submitStopProfileForm: (form: string) => any
    stopVerzekerdeZvZProfiel: (verzekerdeId : number , eindDatum : string) => any
    addVerzekerdeZvZProfiel: (profielId : number, verzekerdeId : number , startDatum : string) => any
}

interface StateProps {
    incontinentieProfiel: IncontinentieProfiel;
    isLoading: boolean;
    from: string;
    to: string;
}

interface MatchParams {
    id: string
}

interface State {
    selectedTabIndex: number;
}

class IncontinentieProfielContainer extends React.Component<DispatchProps & RouteComponentProps<MatchParams> & StateProps, State> {
    constructor(props: DispatchProps & StateProps & RouteComponentProps<MatchParams>) {
        super(props);
        this.state = {
            selectedTabIndex: 0
        }
    }

    onChange = (from: string, to: string) => {
        this.props.setFilterDate(from, to);
    }

    componentDidMount() {
        if (this.props.match)
            this.props.getIncontinentieProfiel(parseInt(this.props.match.params.id));
    }

    setIncontinenceTabIndex = (index: number) => {
        if(index === 0) 
            this.onChange(moment.utc().subtract(1, 'year').format("YYYY-MM"),moment.utc().format("YYYY-MM"))
        else if(index === 1) 
            this.onChange(moment.utc().subtract(1, 'month').format("YYYY-MM"),moment.utc().subtract(1, 'month').format("YYYY-MM"))
        else 
            this.onChange(moment.utc().subtract(1, 'year').format("YYYY-MM"),moment.utc().format("YYYY-MM"))
        
        this.setState({ selectedTabIndex: index })
    }

    render() {
        const { incontinentieProfiel, addVerzekerdeZvZProfiel, submitStopProfileForm, stopVerzekerdeZvZProfiel, from, to } = this.props;


        return (
            <>
                <IncontinentieProfielComponent 
                    from={from}
                    to={to}
                    onChangeDate={this.onChange}
                    stopVerzekerdeZvZProfiel={stopVerzekerdeZvZProfiel}
                    submitForm={submitStopProfileForm}
                    setIncontinenceTabIndex={this.setIncontinenceTabIndex} 
                    incontinentieProfiel={incontinentieProfiel} 
                    incontinenceTabIndex={this.state.selectedTabIndex} 
                    addVerzekerdeZvZProfiel={addVerzekerdeZvZProfiel}
                />
            </>
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    incontinentieProfiel: state.incontinentieProfiel,
    isLoading: state.isLoading,
    from: state.filterDate.from,
    to: state.filterDate.to,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): DispatchProps => ({
    getIncontinentieProfiel: (id: number) =>
        dispatch(getIncontinentieProfiel(id)),
    submitStopProfileForm: (form: string) => dispatch(submit(form)),
    addVerzekerdeZvZProfiel: (profielId : number, verzekerdeId : number , startDatum : string) => dispatch(addVerzekerdeZvZProfiel(profielId, verzekerdeId, startDatum)),
    stopVerzekerdeZvZProfiel: (verzekerdeId : number , eindDatum : string) => dispatch(stopVerzekerdeZvZProfiel(verzekerdeId, eindDatum)),
    setIsLoading: (isLoading: boolean) => dispatch(setIsLoading(isLoading)),
    setFilterDate: (from: string, to: string) => dispatch(setFilterDate(from, to)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IncontinentieProfielContainer));