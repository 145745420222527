import ApiClient from './ApiClient';

import { Account } from "../types/model";

const endpoint = 'account';

export default class AccountApi {
    public static async getAccount(): Promise<Account> {
        var account = await ApiClient.doHttpRequest<Account>('get', `${endpoint}/getuser`);

        return account;
    }

    public static async changePassword(oldpassword: string, newpassword: string): Promise<void> {
        await ApiClient.doHttpRequest<Account>('post', `${endpoint}/changepassword`, { oldpassword: oldpassword, newpassword: newpassword });
    }
}