import * as React from "react";

interface OwnProps {
    onFileChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClickHandler: () => Promise<void>;
    messageUploaded: string;
}

interface State {
    randomNumberKey: number;
    filesSelected: FileList | null;
    isUploading: boolean;
}

export default class UploadComponent extends React.Component<OwnProps, State> {

    constructor(props: OwnProps) {
        super(props);

        this.state = {
            randomNumberKey: 0,
            filesSelected: null,
            isUploading: false
        }
    }


    render() {
        const { onFileChanged, onClickHandler, messageUploaded } = this.props;

        const onChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
            if(event.target.files && event.target.files.length > 0){
                let list = document.getElementById("fileList");
                for(let i = 0; i < event.target.files.length; i++){
                    let item = event.target.files.item(i);
                    if(item != null && list != null){
                        var li = document.createElement('li');
                        li.innerHTML = item.name;

                        list.appendChild(li);
                    }
                }

                this.setState(prev => ({
                    ...prev,
                    filesSelected: event.target.files
                }));

                onFileChanged(event);
            }
        }

        const handleSubmitClick = async () => {
            this.setState(prev => ({
                ...prev,
                isUploading: true
            }));
            await onClickHandler();
            
            let list = document.getElementById("fileList");            
            while (list && list.firstChild)
                list.removeChild(list.firstChild);

            this.setState(prev => ({
                ...prev,
                randomNumberKey: Math.random(),
                isUploading: false
            }));
        }

        return (
            <>
                <div className="column is-full">
                    <div className="bodyHeader column is-full">
                        <div className="column is-one-third">
                            <h1 className="header">Upload</h1>
                        </div>
                    </div>
                </div>

                <div className="column is-full whiteBackground">
                    <div className="fileUpload">
                        <div className="files">
                            <div className="file-uploader__input fileupload">
                                <input type="file" className="file-uploader__input2" onChange={onChanged} multiple={true} key={this.state.randomNumberKey} disabled={this.state.isUploading} />
                            </div>
                        </div>

                        <button className="button is-success btn-block" onClick={handleSubmitClick} disabled={this.state.isUploading}>Upload</button>
                        <ul id='fileList'>
                        </ul>
                        {messageUploaded}
                    </div>
                </div>
            </>
        );
    }
}