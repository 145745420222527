import * as types from "../constants/accountActionTypes";
import { ChangePassword } from "../types/model";

const initialState: ChangePassword = {
    changing: false
};


type State = typeof initialState

export const accountReducer = (
    state: State = initialState,
    action: types.ChangePasswordAction
) => {
    if (action.type === types.REQUEST_CHANGE_PASSWORD)
        return action;
    if (action.type === types.SUCCESS_CHANGE_PASSWORD)
        return action;
    if (action.type === types.FAILURE_CHANGE_PASSWORD)
        return action;

    return state;
};
