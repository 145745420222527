import { setFilterDate } from "../actions/filterActions";
import { getIncontinentieProfielen } from "../actions/incontinentieProfielActions";
import React, { ChangeEvent } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from '../types/state';
import { Column, RowInfo, Filter } from "react-table";
import { Apotheek, IncontinentieProfiel } from "../types/model";
import moment from "moment";
import history from '../components/history';
import matchSorter from "match-sorter";
import { setIsLoading } from "../actions/gridviewActions";
import { FilterFormProps } from "../components/PatientenFilterFormComponent/PatientenFilterFormComponent";
import IncontinentieProfielenComponent from "../components/IncontinentieProfielenComponent/IncontinentieProfielenComponent";

interface DispatchProps {
    setFilterDate: (from: string, to: string) => any;
    getIncontinentieProfielen: (bsnnummer?: number, naam?: string, geboortedatum?: string) => any;
    setIsLoading: (isLoading:boolean) => any;
}

interface StateProps {
    selectedApotheken: Apotheek[];
    from: string;
    to: string;
    incontinentieProfielen: IncontinentieProfiel[];
    isLoading: boolean;
}

class IncontinentieProfielenContainer extends React.Component<DispatchProps & StateProps> {
    onChange = (from: string, to: string) => {
        this.props.setFilterDate(from, to);
    }

    onSubmitFilters = (formValues: FilterFormProps) => {
        this.props.getIncontinentieProfielen(formValues.bsnnummer, formValues.naam, formValues.geboortedatum);
    }

    onRowClick = (event: ChangeEvent, rowInfo: RowInfo) =>
    {
        history.push(`/InconinentieProfiel/${rowInfo.original.id}`, rowInfo.original.id );
    }

    onFilteredChanged = (filter: string, value: string) => {
        
    }

    render() {
        const { from, to, incontinentieProfielen, isLoading } = this.props;

        let columns: Array<Column> = [
            {
                Header: "BSN",
                accessor: "verzekerdeBsn",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [filter.id] }),
                filterAll: true
            },
            {
                Header: "Naam",
                accessor: "verzekerdeNaam",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [filter.id] }),
                filterAll: true
            },
            {
                Header: "Voorletters",
                accessor: "verzekerdeVoorletters",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [filter.id] }),
                filterAll: true
            },
            {
                Header: "Voorvoegsel",
                accessor: "verzekerdeVoorvoegsel",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [filter.id] }),
                filterAll: true
            },
            {
                Header: "Geboortedatum",
                accessor: "verzekerdeGeboortedatum",
                headerStyle: {
                    textAlign: "left"
                },
                Cell: props => moment(props.value).format("DD-MM-YYYY"),
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [filter.id] }),
                filterAll: true
            }
        ]

        return (
            <>
                <IncontinentieProfielenComponent onSubmitFilters={this.onSubmitFilters} onRowClick={this.onRowClick} onChange={this.onChange} onFilteredChanged={this.onFilteredChanged} from={from} to={to} columns={columns} incontinentieProfielen={incontinentieProfielen} isLoading={isLoading} />
            </>
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    selectedApotheken: state.selectedApotheken,
    from: state.filterDate.from,
    to: state.filterDate.to,
    incontinentieProfielen: state.incontinentieProfielen,
    isLoading: state.isLoading
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): DispatchProps => ({
    setFilterDate: (from: string, to: string) => dispatch(setFilterDate(from, to)),
    getIncontinentieProfielen: (bsnnummer?: number, naam?: string, geboortedatum?: string) => 
        dispatch(getIncontinentieProfielen(bsnnummer, naam, geboortedatum)),
    setIsLoading: (isLoading:boolean) => dispatch(setIsLoading(isLoading))
});

export default connect(mapStateToProps, mapDispatchToProps)(IncontinentieProfielenContainer);