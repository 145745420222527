import HeaderComponent from "../components/HeaderComponent/HeaderComponent";
import { getApotheken, setSelectedApotheken, getApothekenap304 } from '../actions/apotheekActions';
import { getAp304BestandProgress, getAp304BestandTotal } from "../actions/ap304Actions";
import { getPrestatiesForPatienten, getPrestatiesForMiddelen, getPrestatiesForActies } from "../actions/prestatieActions";
import React from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from '../types/state';
import { Apotheek } from "../types/model";
import { authService } from "../services/AuthService";
import ApotheekApi from "../api/ApotheekApi";

interface DispatchProps {
    getApotheken: () => any;
    setSelectedApotheken: (payload: Apotheek[]) => any;
    getAp304BestandProgress: (selectedApotheken: number[], from: string, to: string, useIsLoading:boolean) => any;
    //getPrestatiesForFacturen: (selectedApotheken: number[], from: string, to: string) => any;
    getPrestatiesForPatienten: (selectedApotheken: number[], from: string, to: string) => any;
    getPrestatiesForMiddelen: (selectedApotheken: number[], from: string, to: string) => any;
    getPrestatiesForActies: (selectedApotheken: number[], from: string, to: string) => any;
    getApothekenap304: (selectedApotheekIds: number[], from: string, to: string) => any;
    getAp304BestandTotal: (selectedApotheken: number[], from: string, to: string) => any;
}

interface StateProps {
    apotheken: Apotheek[];
    selectedApotheken: Apotheek[];
    selectedFactuur: string;
    from: string;
    to: string;
}

class HeaderContainer extends React.Component<DispatchProps & StateProps> {
    onChange = async (selectedApotheken: any) => {
        let _selectedApotheken = selectedApotheken as Apotheek[];

        if (_selectedApotheken.length === 0)
            _selectedApotheken = this.props.apotheken;

        this.props.setSelectedApotheken(_selectedApotheken);

        this.props.getAp304BestandProgress(_selectedApotheken.map(a => a.id), this.props.from, this.props.to, window.location.pathname === '/Facturen');
        this.props.getAp304BestandTotal(this.props.selectedApotheken.map(a => a.id), this.props.from, this.props.to);
        
        if(window.location.pathname === '/'){
            this.props.getApothekenap304(_selectedApotheken.map(a => a.id), this.props.from, this.props.to);
        }
        if(window.location.pathname === '/Patienten'){
            this.props.getPrestatiesForPatienten(_selectedApotheken.map(a => a.id), this.props.from, this.props.to);
        }
        // if(window.location.pathname === '/Middelen'){    
        //     this.props.getPrestatiesForMiddelen(_selectedApotheken.map(a => a.id), this.props.from, this.props.to);
        // }
        if(window.location.pathname === '/Acties'){    
            this.props.getPrestatiesForActies(_selectedApotheken.map(a => a.id), this.props.from, this.props.to);
        }
        
    }

    onLogout = async () => {
        await authService.signOut();
    }

    componentDidMount() {
        this.props.getApotheken();

        ApotheekApi.getApotheken().then(apotheken => this.onChange(apotheken));
    }

    render() {
        let userName = sessionStorage.getItem('userName');

        let isUploadUser = sessionStorage.getItem('isUploadUser');
        if (isUploadUser == null)
            isUploadUser = "True";

        if (userName == null)
            userName = "";
        const { apotheken, selectedApotheken } = this.props;

        return (
            <HeaderComponent isUploadUser={isUploadUser} accountName={userName} apotheken={apotheken} selectedApotheken={selectedApotheken} onChange={this.onChange} onLogout={this.onLogout} />
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    selectedApotheken: state.selectedApotheken,
    from: state.filterDate.from,
    to: state.filterDate.to,
    apotheken: state.apotheken,
    selectedFactuur: state.selectedFactuur
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): DispatchProps => ({
    getApotheken: () => dispatch(getApotheken()),
    setSelectedApotheken: (payload: Apotheek[]) => dispatch(setSelectedApotheken(payload)),
    getAp304BestandProgress: (selectedApotheken: number[], from: string, to: string, useIsLoading:boolean) => dispatch(getAp304BestandProgress(selectedApotheken, from, to,useIsLoading)),
    //getPrestatiesForFacturen: (selectedApotheken: number[], from: string, to: string) => dispatch(getPrestatiesForFacturen(selectedApotheken, from, to)),
    getPrestatiesForPatienten: (selectedApotheken: number[], from: string, to: string) => dispatch(getPrestatiesForPatienten(selectedApotheken, from, to)),
    getPrestatiesForMiddelen: (selectedApotheken: number[], from: string, to: string) => dispatch(getPrestatiesForMiddelen(selectedApotheken, from, to)),
    getPrestatiesForActies: (selectedApotheken: number[], from: string, to: string) => dispatch(getPrestatiesForActies(selectedApotheken, from, to)),
    getApothekenap304: (selectedApotheekIds: number[], from: string, to: string) => dispatch(getApothekenap304(selectedApotheekIds, from, to)),
    getAp304BestandTotal: (selectedApotheken: number[], from: string, to: string) => dispatch(getAp304BestandTotal(selectedApotheken, from, to))
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
