import * as actionTypes from "../constants/gridviewActionTypes";
import { AsyncAction } from ".";
import { SetGridviewAction, SetIsLoading } from "../constants/gridviewActionTypes";

export const setGridviewFilter = (gridviewId: string, filterId: string, value: string): AsyncAction<SetGridviewAction> => {
    return async (dispatch) => {
        return dispatch({ type: actionTypes.SET_GRIDVIEW, gridviewId, filterId, value });
    }
}

export const setIsLoading = (isLoading:boolean):AsyncAction<SetIsLoading> => {
    return async(dispatch) => {
        return dispatch({ type: actionTypes.SET_ISLOADING, isLoading: isLoading})
    }
}