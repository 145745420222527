import axios from "axios";
import { authService } from "../services/AuthService";
import { getSettings } from "../types/settings";
type Method = "head" | "link" | "get" | "GET" | "delete" | "DELETE" | "HEAD" | "options" | "OPTIONS" | "post" | "POST" | "put" | "PUT" | "patch" | "PATCH" | "purge" | "PURGE" | "LINK" | "unlink" | "UNLINK" | undefined ;

class ApiClient {
    public static async doHttpRequest<TResponse>(
        method: Method,
        endpoint: string,
        data: {} | PromiseLike<{}> | null = null,
        asFormValues = false,
        authenticated = true
    ): Promise<TResponse> {
        const settings = getSettings();

        const headers: any = {};

        if (!asFormValues) {
            headers["Content-Type"] = "application/json";
        }
        if (authenticated) {
            headers["Authorization"] = "Bearer " + await authService.getToken();
        }

        const url = `${settings.apiUrl}/${endpoint}`;

        const response = await axios.request<TResponse>({ method, url, data, headers });

        return response.data;
    }
}

export default ApiClient;
