import * as types from "../constants/filterSettingTypes";
import {Filter} from "react-table"

const filterSettings = [] as Filter[]
const initialState = { filterSettings: filterSettings }

type State = typeof initialState

export const setFilterSettingsReducer = (
    state: State = initialState,
    action: types.SetFilterSettingAction
) => {
    if (action.type === types.SET_FILTER_SETTING)
        return { ...state, filterSettings: action.filterSettings};

    return state;
};