import * as types from "../constants/filterActionTypes";
import moment from "moment";

const dateNow = moment().format('YYYY-MM');
const dateLastMonth = moment().format('YYYY-MM');
const initialState = { from: dateLastMonth, to: dateNow }

type State = typeof initialState

export const filterDateReducer = (
    state: State = initialState,
    action: types.FilterDateAction
) => {
    if (action.type === types.SET_FILTER_DATE)
        return { ...state, from: action.from, to: action.to };

    return state;
};
