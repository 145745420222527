import { Action } from "redux";
import { Apotheek, ApotheekAp304 } from "../types/model";

export const GET_APOTHEKEN = "GET_APOTHEKEN";
export const GET_APOTHEKENAP304 = "GET_APOTHEKENAP304";
export const SET_SELECTED_APOTHEKEN = "SET_SELECTED_APOTHEKEN";

export interface GetApothekenAp304Action extends Action<string> {
    apotheekAp304: ApotheekAp304[];
}

export interface GetApothekenAction extends Action<string> {
    apotheken: Apotheek[];
}

export interface SetSelectedApothekenAction extends Action<string> {
    selectedApotheken: Apotheek[];
}