import * as actionTypes from "../constants/middelenActionTypes";

import { AsyncAction } from ".";
import MiddelApi from "../api/MiddelenApi";
import { setIsLoading } from "./gridviewActions";

export const getMiddelen = (taxeMaand?:string, artikelOmschrijving?:string, ziNummer?:number): AsyncAction<actionTypes.GetMiddelenAction> => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));        
        const middelen = await MiddelApi.getMiddelen(taxeMaand, artikelOmschrijving, ziNummer).then()
            .finally(() => {
                dispatch(setIsLoading(false));
            }); 

        return dispatch({ type: actionTypes.GET_MIDDELEN, middelen });
    };
}
