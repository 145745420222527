import React from "react";
import UploadComponent from "../components/UploadComponent/UploadComponent";
import UploadApi from "../api/UploadApi";
import { FileModel } from "../types/model";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

interface Props {
}

interface State {
    selectedFiles: FileModel[];
    messageUploaded: string;
}

class UploadContainer extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            selectedFiles: [] as FileModel[],
            messageUploaded: ''
        }
    }

    onUploaded = async () => {
        toast.success("Upload finished");
    }

    onFileChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        if (event.target.files && event.target.files.length > 0) {
            this.setState({
                selectedFiles: [] as FileModel[],
                messageUploaded: ""
            });

            for (var x = 0; x < event.target.files.length; x++) {
                var file = event.target.files[x];
                const fileName = file.name;
                
                var reader = new FileReader();
                reader.readAsDataURL(file);

                reader.addEventListener("loadend", ev => {
                    const dataString = ev.target?.result as string;
                    const strippedData = dataString.substring(dataString.indexOf(",") + 1).trim();
                    const data = { fileName, content: strippedData } as FileModel;

                    this.setState({
                        selectedFiles: [...this.state.selectedFiles, data]
                    });
                });                
            }
        }
    };

    onClickHandler = async () => {
        if (this.state.selectedFiles && this.state.selectedFiles.length > 0) {
            await UploadApi.uploadFile(this.state.selectedFiles);

            this.onUploaded();

            this.setState({
                selectedFiles: [] as FileModel[],
                messageUploaded: "Bestanden verstuurd!"
            });
        }
    }

    render() {
        const { messageUploaded } = this.state;
        return (
            <>
                <UploadComponent messageUploaded={messageUploaded} onFileChanged={this.onFileChanged} onClickHandler={this.onClickHandler} />
            </>
        );
    }
}


export default UploadContainer;