import React from "react";
import { Field, reduxForm, InjectedFormProps } from "redux-form";
import { LabelInput } from "../LabelInput";
import { DeclaratieRegel, PrijsberekeningType } from "../../types/model";
import { HerdeclaratieField } from "./HerdeclaratieField";
import PrestatieApi from "../../api/PrestatieApi";
import classNames from "classnames";
import { HerdeclaratieDateField } from "./HerdeclaratieDateField";

export interface OwnProps {
  declaratieRegel: DeclaratieRegel | null;
  onClose: () => void;
  prestatieId: number;
}

interface State {
  herdeclareren: boolean;
  declaratieRegel: DeclaratieRegel | null;
  aantalGeleverd: {
    [key: number]: string;
  };
  artikelImportBedrag: {
    [key: number]: string;
  };
}

type Props = OwnProps & InjectedFormProps<{}, OwnProps>;

class DeclaratieRegelDetailComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      herdeclareren: false,
      declaratieRegel: null,
      aantalGeleverd: {},
      artikelImportBedrag: {},
    };
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (
      this.props.declaratieRegel &&
      this.props.declaratieRegel.naam &&
      !this.state.declaratieRegel
    )
      this.setState((curr) => {
        let state: State = {
          ...curr,
          declaratieRegel: this.props.declaratieRegel,
        };

        for (let regel of this.props.declaratieRegel!.regels) {
          state.aantalGeleverd[regel.id] = regel.aantalGeleverd.toFixed(2);
          state.artikelImportBedrag[regel.id] =
            regel.artikelImportBedrag.toFixed(2);
        }

        return state;
      });
  }

  onFinishHerdeclaratie() {
    const declaratieRegel = this.state.declaratieRegel!;

    PrestatieApi.createHerdeclaratie({
      agbBehandelaar: declaratieRegel.agbBehandelaar,
      agbInstelling: declaratieRegel.agbInstelling,
      agbVoorschrijver: declaratieRegel.agbVoorschrijver,
      bsnNummer: declaratieRegel.bsnNummer,
      datumPrestatie: declaratieRegel.datumLevering,
      soortBehandelaar: declaratieRegel.soortBehandelaar,
      soortVoorschrijver: declaratieRegel.soortVoorschrijver,
      uzovi: declaratieRegel.uzovi,
      geboorteDatum: declaratieRegel.geboorteDatum,
      verzekerdeNummer: declaratieRegel.verzekerdeNummer,
      regels: declaratieRegel.regels.map((r) => ({
        id: r.id,
        prijs: r.artikelImportBedrag,
        aantalUitgevoerdePrestaties: r.aantalGeleverd,
        prestatiecode1: r.ziNummer,
      })),
    }).then(() => this.props.onClose());
  }

  render() {
    if (!this.state.declaratieRegel) return null;

    const declaratieRegel = {
      ...this.state.declaratieRegel,
      ...this.state.declaratieRegel.regels.find(
        (r) => r.id === this.state.declaratieRegel?.id
      )!,
    };

    return (
      <>
        <div className="topBar">
          <div>Declaratie</div>
          {declaratieRegel.artikelToegekendBedrag === 0 && (
            <button
              className={classNames(
                "button",
                declaratieRegel.isHerdeclareerd ? "is-dark" : "is-info"
              )}
              onClick={() => this.setState({ herdeclareren: true })}
              disabled={
                this.state.herdeclareren || declaratieRegel.isHerdeclareerd
              }
            >
              Herdeclareren
            </button>
          )}
        </div>

        <div className="column is-half maincontent">
          <div className="column contentPanel is-full">
            <div className="column subHeader is-full">
              <h1>Patient</h1>
            </div>

            <Field
              component={LabelInput}
              label="Naam"
              name="naam"
              showSideBySide={true}
              text={declaratieRegel.naam}
            />

            <HerdeclaratieField
              editable={this.state.herdeclareren}
              value={declaratieRegel.uzovi}
              onChange={(v) =>
                this.setState({
                  ...this.state,
                  declaratieRegel: {
                    ...this.state.declaratieRegel!,
                    uzovi: v,
                  },
                })
              }
              label="Verzekeraar"
            />

            <HerdeclaratieField
              editable={this.state.herdeclareren}
              value={declaratieRegel.bsnNummer}
              onChange={(v) =>
                this.setState({
                  ...this.state,
                  declaratieRegel: {
                    ...this.state.declaratieRegel!,
                    bsnNummer: v,
                  },
                })
              }
              label="BSN"
              onClick={() => {
                window.location.href = `/Patienten?bsn=${declaratieRegel.bsnNummer}`;
              }}
            />

            <HerdeclaratieDateField
              editable={this.state.herdeclareren}
              value={declaratieRegel.geboorteDatum}
              onChange={(v) =>
                this.setState({
                  ...this.state,
                  declaratieRegel: {
                    ...this.state.declaratieRegel!,
                    geboorteDatum: v,
                  },
                })
              }
              label="Geboortedatum"
            />

            <HerdeclaratieField
              editable={this.state.herdeclareren}
              value={declaratieRegel.verzekerdeNummer}
              onChange={(v) =>
                this.setState({
                  ...this.state,
                  declaratieRegel: {
                    ...this.state.declaratieRegel!,
                    verzekerdeNummer: v,
                  },
                })
              }
              label="Verzekerdenummer"
            />

            <div className="bottomBorder"></div>
          </div>

          <div className="column contentPanel is-full">
            <div className="column subHeader is-full">
              <h1>Betrokkenen</h1>
            </div>

            <HerdeclaratieField
              editable={this.state.herdeclareren}
              value={declaratieRegel.agbVoorschrijver}
              onChange={(v) =>
                this.setState({
                  ...this.state,
                  declaratieRegel: {
                    ...this.state.declaratieRegel!,
                    agbVoorschrijver: v,
                  },
                })
              }
              label="AGB Voorschrijver"
            />

            <HerdeclaratieField
              editable={this.state.herdeclareren}
              value={declaratieRegel.agbBehandelaar}
              onChange={(v) =>
                this.setState({
                  ...this.state,
                  declaratieRegel: {
                    ...this.state.declaratieRegel!,
                    agbBehandelaar: v,
                  },
                })
              }
              label="AGB Behandelaar"
            />

            <HerdeclaratieField
              editable={this.state.herdeclareren}
              value={declaratieRegel.agbInstelling}
              onChange={(v) =>
                this.setState({
                  ...this.state,
                  declaratieRegel: {
                    ...this.state.declaratieRegel!,
                    agbInstelling: v,
                  },
                })
              }
              label="AGB Instelling"
            />

            <div className="bottomBorder"></div>
          </div>

          <div className="column contentPanel is-full">
            <div className="column subHeader is-full">
              <h1>Declaratie</h1>
            </div>
            <Field
              component={LabelInput}
              label="Receptnummer"
              name="receptNummer"
              showSideBySide={true}
              text={declaratieRegel.receptNummer}
            />

            <HerdeclaratieDateField
              editable={this.state.herdeclareren}
              value={declaratieRegel.datumLevering}
              onChange={(v) =>
                this.setState({
                  ...this.state,
                  declaratieRegel: {
                    ...this.state.declaratieRegel!,
                    datumLevering: v,
                  },
                })
              }
              label="Datum levering"
            />

            {declaratieRegel.prijsberekeningType !== null && (
              <Field
                component={LabelInput}
                label="Type prijsberekening"
                name="prijsberekeningType"
                showSideBySide={true}
                text={
                  {
                    [PrijsberekeningType.Geen]: "Geen",
                    [PrijsberekeningType.Bwmg]: "BWMG",
                    [PrijsberekeningType.BwmgAflevering]: "BWMG Aflevering",
                    [PrijsberekeningType.Idea]: "IDEA",
                    [PrijsberekeningType.LpgBinnen]: "Binnen LPG",
                    [PrijsberekeningType.LpgBuiten]: "Buiten LPG",
                    [PrijsberekeningType.Overig]: "Overig",
                    [PrijsberekeningType.Preferentiebeleid]:
                      "Preferentiebeleid",
                    [PrijsberekeningType.Uitzondering]: "Uitzondering",
                  }[declaratieRegel.prijsberekeningType]
                }
              />
            )}

            {declaratieRegel.referentieArtikelNaam !== null && (
              <Field
                component={LabelInput}
                label="LPG referentieartikel"
                name="referentieArtikelNaam"
                showSideBySide={true}
                text={declaratieRegel.referentieArtikelNaam}
              />
            )}

            <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-full-desktop">
              <div className="ReactTable">
                <div className="rt-thead -header artikelPrestatie">
                  <div className="rt-tr">
                    <div className="rt-th rt-resizable-header">
                      <div className="rt-resizable-header-content">
                        Artikelnummer
                      </div>
                    </div>
                    <div className="rt-th rt-resizable-header">
                      <div className="rt-resizable-header-content">Artikel</div>
                    </div>
                    <div className="rt-th rt-resizable-header">
                      <div className="rt-resizable-header-content">
                        Aantal geleverd
                      </div>
                    </div>
                    <div className="rt-th rt-resizable-header">
                      <div className="rt-resizable-header-content">
                        Gedeclareerde prijs
                      </div>
                    </div>
                    <div className="rt-th rt-resizable-header">
                      <div className="rt-resizable-header-content">
                        TRINGL prijs
                      </div>
                    </div>
                    <div className="rt-th rt-resizable-header">
                      <div className="rt-resizable-header-content">
                        Uitgekeerd bedrag
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rt-tbody">
                  <div className="rt-tr-group">
                    {declaratieRegel.regels.map((r) => (
                      <div className="rt-tr -padRow -odd">
                        <div className="rt-td">
                          {r.aanduiding === 60 ? (
                            "Prestatie"
                          ) : this.state.herdeclareren ? (
                            <HerdeclaratieInput
                              value={r.ziNummer}
                              onChange={(e) => {
                                r.ziNummer = e.target.value;

                                this.setState({
                                  ...this.state,
                                  declaratieRegel: {
                                    ...this.state.declaratieRegel!,
                                    regels: [
                                      ...this.state.declaratieRegel!.regels,
                                    ],
                                  },
                                });
                              }}
                            />
                          ) : (
                            r.ziNummer
                          )}
                        </div>
                        <div className="rt-td" title={r.aanduiding !== 60 ? r.artikelOmschrijving : ""}>
                          {r.aanduiding !== 60 ? (r.aanduiding != 1 ? "Bereiding" : r.artikelOmschrijving) : ""}
                        </div>
                        <div className="rt-td">
                          {r.aanduiding !== 60 && (
                            <span>
                              {this.state.herdeclareren ? (
                                <HerdeclaratieInput
                                  value={this.state.aantalGeleverd[r.id]}
                                  onChange={(e) => {
                                    const value = parseFloat(e.target.value);

                                    if (!isNaN(value)) {
                                      r.aantalGeleverd = value;
                                    }

                                    this.setState({
                                      ...this.state,
                                      declaratieRegel: {
                                        ...this.state.declaratieRegel!,
                                        regels: [
                                          ...this.state.declaratieRegel!.regels,
                                        ],
                                      },
                                      aantalGeleverd: {
                                        ...this.state.aantalGeleverd,
                                        [r.id]: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              ) : (
                                r.aantalGeleverd
                              )}{" "}
                              {r.eenheid}
                            </span>
                          )}
                        </div>

                        <div className="rt-td">
                          {this.state.herdeclareren ? (
                            <HerdeclaratieInput
                              value={this.state.artikelImportBedrag[r.id]}
                              onChange={(e) => {
                                const value = parseFloat(e.target.value);

                                if (!isNaN(value)) {
                                  r.artikelImportBedrag = value;
                                }

                                this.setState({
                                  ...this.state,
                                  declaratieRegel: {
                                    ...this.state.declaratieRegel!,
                                    regels: [
                                      ...this.state.declaratieRegel!.regels,
                                    ],
                                  },
                                  artikelImportBedrag: {
                                    ...this.state.artikelImportBedrag,
                                    [r.id]: e.target.value,
                                  },
                                });
                              }}
                            />
                          ) : r.artikelImportBedrag != null ? (
                            "€" + r.artikelImportBedrag.toFixed(2)
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="rt-td">
                          {r.artikelTringlBedrag != null
                            ? "€" + r.artikelTringlBedrag.toFixed(2)
                            : ""}
                        </div>
                        <div className="rt-td">
                          {r.artikelToegekendBedrag != null
                            ? "€" + r.artikelToegekendBedrag.toFixed(2)
                            : ""}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="bottomBorder"></div>
          </div>

          <div className="column contentPanel is-full">
            <div className="column subHeader is-full">
              <h1>Reden afkeur:</h1>
            </div>
            <div id="afkeurReden">
              <span>Code {declaratieRegel.afkeurReden}</span>
            </div>
          </div>

          <div className="columns contentPanel">
            <div className="column is-full">
              <div className="buttons is-pulled-right">
                <button
                  className="button is-danger"
                  onClick={this.props.onClose}
                >
                  {this.state.herdeclareren ? "Annuleren" : "Sluiten"}
                </button>
                {this.state.herdeclareren && (
                  <button
                    className="button is-success"
                    onClick={() => this.onFinishHerdeclaratie()}
                  >
                    Herdeclaratie klaarzetten
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const HerdeclaratieInput = (
  props: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
) => {
  return <input type="text" style={{ width: "90px" }} {...props} />;
};

export default reduxForm<{}, OwnProps>({
  form: "DetailForm",
})(DeclaratieRegelDetailComponent);
