import React from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from '../types/state';
import AccountComponent, { WachtwoordProps } from "../components/AccountComponent/AccountComponent";
import { changePassword } from "../actions/AccountActions";
import history from "../components/history";

interface DispatchProps {
    changePassword: (newPassword: string, oldPassword: string) => void;
}

interface StateProps {
}


class AccountContainer extends React.Component<DispatchProps & StateProps> {

    onSubmit = (formValues: WachtwoordProps) => {
        if(formValues.newPassword === formValues.confirmPassword)
        {
            this.props.changePassword(formValues.newPassword, formValues.oldPassword);
            history.push("/");
        }
        
    }

    componentDidMount() {
    }

    render() {


        return (
            <>
                <AccountComponent onSubmit={this.onSubmit} />
            </>
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): DispatchProps => ({
    changePassword: (newPassword: string, oldPassword: string) => dispatch(changePassword(oldPassword, newPassword))
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountContainer);