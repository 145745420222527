import * as React from "react";
import MonthPicker from "../MonthPicker";
import { Column , Filter, SortingRule} from "react-table";
import Gridview from "../Gridview/Gridview";
import { Ap304BestandProgress, Statistieken, ApotheekAp304, Apotheek } from "../../types/model";
import ProgressBar from "../ProgressBar/ProgressBar";

interface OwnProps {
    selectedApotheken: Apotheek[];
    onChange: (from: string, to: string) => void;
    onRowClick?: (event: any, rowInfo: any) => void;
    onFilteredChanged: (filter: string, value: string) => void;
    onSortingChanged: (column: string, desc: boolean) => void;
    from: string;
    to: string;
    selectedFactuur: string;
    columns: Array<Column>;
    ap304BestandProgress: Ap304BestandProgress;
    apotheekAp304: ApotheekAp304[];
    isLoading: boolean;
    filterSettings: Filter[];
    sortingSettings: SortingRule[];
}

const names = ["Ingediend", "TRINGL", "VECOZO", "Retour ZVZ"];

export default class FacturenComponent extends React.Component<OwnProps> {
    render() {
        const { onChange, onRowClick, onFilteredChanged, onSortingChanged, from, to, columns, ap304BestandProgress, isLoading, selectedApotheken } = this.props;

        let currentStep: number = 1

        let statistics: Statistieken[] = []
        if (ap304BestandProgress) {
            if (ap304BestandProgress.toegekendStatistieken.aantalBestanden > 0) {
                currentStep = 4
            }
            else if (ap304BestandProgress.opgestuurdStatistieken.aantalBestanden > 0) {
                currentStep = 3
            }
            else if (ap304BestandProgress.tringlStatistieken.aantalBestanden > 0) {
                currentStep = 2
            }
            else {
                currentStep = 1;
            }

            statistics = [ap304BestandProgress.importStatistieken, ap304BestandProgress.tringlStatistieken, ap304BestandProgress.opgestuurdStatistieken, ap304BestandProgress.toegekendStatistieken]
        }

        return (
            <>
                <div className="column is-full">
                    <div className="bodyHeader column is-full">
                        <div className="column is-one-third">
                            <h1>Facturen</h1>
                            <div className="totalGrid">
                                <div className="column is-half">
                                    <h1>{statistics[0].regels}</h1>
                                    <span>Declaratieregels</span>
                                </div>
                                <div className="column is-half">
                                    <h1>{new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(statistics[0].bedrag)}</h1>
                                    <span>Euro gedeclareerd</span>
                                </div>
                                <div className="column is-full">
                                    <h1>{statistics[0].aantalPatienten}</h1>
                                    <span>Patienten</span>
                                </div>
                            </div>
                        </div>
                        <div className="column is-two-thirds align-top">
                            <div className="column is-one-third">
                                <h1>Huidige vooruitgang</h1>
                            </div>
                            <MonthPicker
                                maxOneMonthDifference={true}
                                onChange={onChange} 
                                from={from} 
                                to={to}
                            />
                            <div className="column is-full">
                                <ProgressBar stepCount={4} currentStep={currentStep} names={names} statistics={statistics} />
                            </div>
                        </div>
                    </div>
                    
                    <div className="bodyHeader column is-full">
                        {selectedApotheken.map( (value:Apotheek) =>         
                            <div key={value.id} className="apotheekLabel">
                                {value.naam}
                            </div>)
                        }   
                    </div>
                </div>
                <div className="column is-full whiteBackground">
                    <div className="body content">

                        <Gridview filterSettings={this.props.filterSettings} sortingSettings={this.props.sortingSettings} usePaging={false} onRowClick={onRowClick} onFilteredChanged={onFilteredChanged} onSortingChanged={onSortingChanged} columns={columns} data={ap304BestandProgress != null ? ap304BestandProgress.ap304Bestanden : []} height="" isLoading={isLoading} />
                    </div>
                </div>
            </>
        );
    }
}
