import React from "react";

interface Props {
  label: string;
  value: string | number;
  onChange: (value: string) => void;
  editable: boolean;
  onClick?: () => void;
}

export class HerdeclaratieField extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.state = {
      value: ""
    };
  }
  render() {
    return (
      <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-half-desktop">
        <div className="field-label">
          <label className="label tringlLabel is-pulled-left">
            {this.props.label}
          </label>
        </div>
        <div
          className="field-body"
          style={
            !this.props.editable && this.props.onClick
              ? { textDecoration: "underline", cursor: "pointer" }
              : undefined
          }
          onClick={!this.props.editable ? this.props.onClick : undefined}
        >
          {this.props.editable ? (
            <input
              type={typeof this.props.value === "number" ? "number" : "text"}
              onChange={(e) => this.props.onChange(e.target.value)}
              value={this.props.value}
            />
          ) : (
            this.props.value
          )}
        </div>
      </div>
    );
  }
}
