import React, { useEffect, useState } from "react";
import { Field, reduxForm, InjectedFormProps } from "redux-form";
import { TextInput } from "../TextInput";
import { DateInput } from "../DateInput";
import moment from "moment";
import classnames from "classnames";
import { MonthInput } from "../MonthInput";

export interface FilterFormProps {
  ziNummer?: number;
  artikelOmschrijving?: string;
  taxeMaand?: string;
}

const MiddelenFilterFormComponent: React.FunctionComponent<
  InjectedFormProps<FilterFormProps>
> = (props) => {
  const { handleSubmit, pristine, submitting } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Field
        component={TextInput}
        type="text"
        name="ziNummer"
        className="input"
        placeholder="Zi-nummer"
        label="Zi-nummer"
        customWrapperClass="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-one-quarter-desktop"
      />
      <Field
        component={TextInput}
        type="text"
        name="artikelOmschrijving"
        className="input"
        placeholder="Artikel omschrijving"
        label="Artikel omschrijving"
        customWrapperClass="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-one-quarter-desktop"
      />
      {/* <Field
        component={DateInput}
        type="month"
        name="taxeMaand"
        placeholder="Taxe maand"
        label="Taxe maand"
        customWrapperClass="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-one-quarter-desktop"
        defaultValue={moment().format("YYYY-MM")}
      /> */}

      <Field
        component={MonthInput}
        name="taxeMaand"
        label="Taxe maand"
        className="input"
        customWrapperClass="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-one-quarter-desktop"
      />

      <div className="field column is-horizontal is-marginless is-full-mobile is-full-tablet is-one-quarter-desktop">
        <button
          className="button is-dark"
          type="submit"
          disabled={pristine || submitting}
        >
          Zoeken
        </button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: "MiddelenFilterForm",
  initialValues: {
    taxeMaand: moment().format("YYYY-MM"),
  },
})(MiddelenFilterFormComponent);
