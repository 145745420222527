import { combineReducers } from "redux";
import { loginReducer } from "./loginReducer";
import { apothekenReducer, selectedApothekenReducer, apothekenAp304Reducer } from "./apotheekReducer";
import { filterDateReducer } from "./filterReducer";
import { ap304Reducer, ap304TotalReducer } from "./ap304Reducer";
import { selectedFactuurReducer, facturenReducer, patientenReducer, actiesReducer, declaratieRegelReducer } from "./prestatieReducer";
import { reducer as formReducer } from 'redux-form';
import { middelenReducer } from './middelenReducer';
import { accountReducer} from './accountReducer';
import { loadingReducer } from "./gridviewReducer";
import { alertReducer } from "./alertReducer";
import { incontinentieprofielenReducer, incontinentieprofielReducer } from "./incontinentieProfielReducer";
import { setFilterSettingsReducer } from "./filterSettingsReducer";
import { setSortingSettingsReducer } from "./sortingSettingsReducer";

export const rootReducer = combineReducers({
    login: loginReducer,
    apotheken: apothekenReducer,
    apotheekAp304: apothekenAp304Reducer,
    selectedApotheken: selectedApothekenReducer,
    filterDate: filterDateReducer,
    filterSettings: setFilterSettingsReducer,
    sortingSettings: setSortingSettingsReducer,
    isLoading: loadingReducer,
    ap304BestandProgress: ap304Reducer,
    ap304BestandTotal : ap304TotalReducer,
    facturen: facturenReducer,
    selectedFactuur: selectedFactuurReducer,
    incontinentieProfielen: incontinentieprofielenReducer,
    incontinentieProfiel: incontinentieprofielReducer,
    patienten: patientenReducer,
    middelen: middelenReducer,
    acties: actiesReducer,
    declaratieRegel: declaratieRegelReducer,
    account: accountReducer,
    alerts: alertReducer,
    form: formReducer,
});