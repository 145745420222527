import { LoginState } from "../types/state";
import * as types from "../constants/loginActionTypes";
import jwtDecode from 'jwt-decode';

const initialState: LoginState = {
    userId: undefined,
    token: undefined
};

interface TokenProperties {
    sub: string;
    name: string;
}

export const loginReducer = (
    state: LoginState = initialState,
    action: types.LoginAction
) => {
    if (action.type === types.LOGIN) {
        const decodedToken = jwtDecode<TokenProperties>(action.token);
        sessionStorage.setItem('userId', decodedToken.sub);
        sessionStorage.setItem('userName', decodedToken.name);
        sessionStorage.setItem('isUploadUser', "False");

        // if (decodedToken.IsUploadUser === "False")
        //     window.location.href = "/";
        // else
        //     window.location.href = "/Upload";

        return { ...state };
    }

    return { ...state };
};
