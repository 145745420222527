import * as actionTypes from "../constants/apotheekActionTypes";
import ApotheekApi from '../api/ApotheekApi';
import { GetApothekenAction, SetSelectedApothekenAction } from "../constants/apotheekActionTypes";
import { AsyncAction } from ".";
import { Apotheek } from "../types/model";
import { setIsLoading } from "./gridviewActions";

export const getApotheken = (): AsyncAction<GetApothekenAction> => {
    return async (dispatch) => {
        const apotheken = await ApotheekApi.getApotheken();

        await dispatch(setSelectedApotheken(apotheken));
        
        return dispatch({ type: actionTypes.GET_APOTHEKEN, apotheken });
    };
}

export const getApothekenap304 = (selectedApotheekIds: number[], from: string, to: string): AsyncAction<actionTypes.GetApothekenAp304Action> => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        const apotheekAp304 = await ApotheekApi.getApothekenap304(selectedApotheekIds, from, to)
        .finally(() => {
            dispatch(setIsLoading(false));
        });
        
        //setTimeout(() => dispatch(setIsLoading(false)), 3500);

        return dispatch({ type: actionTypes.GET_APOTHEKENAP304, apotheekAp304 });
    };
}

export const setSelectedApotheken = (selectedApotheken: Apotheek[]): AsyncAction<SetSelectedApothekenAction> => {
    return async (dispatch) => {
        return dispatch({ type: actionTypes.SET_SELECTED_APOTHEKEN, selectedApotheken });
    }
}
