import * as actionTypes from "../constants/alertActionTypes";

import { AsyncAction } from ".";
import { setIsLoading } from "./gridviewActions";
import AlertApi from "../api/AlertApi";

export const getTringlAlerts = (selectedApotheken: number[], from: string, to: string): AsyncAction<actionTypes.GetAlertsAction> => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));

        const alerts = await AlertApi.getAlerts(selectedApotheken, from, to)
        .finally(() => {
            dispatch(setIsLoading(false));
        });
        

        return dispatch({ type: actionTypes.GET_TRINGLALERTS, alerts });
    };
}