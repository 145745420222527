import * as types from "../constants/prestatieActionTypes";
import {
  Factuur,
  Patient,
  Middel,
  Actie,
  DeclaratieRegel,
} from "../types/model";

const initialFacturenState: Array<Factuur> = [];
const initialPatientenState: Array<Patient> = [];
const initialMiddelenState: Array<Middel> = [];
const initialActiesState: Array<Actie> = [];
const initialDeclaratieRegelState = {
  referentieArtikelNaam: null,
} as DeclaratieRegel;
const initialSelectedFactuurState: string = "";

type FacturenState = typeof initialFacturenState;
type PatientenState = typeof initialPatientenState;
type MiddelenState = typeof initialMiddelenState;
type ActiesState = typeof initialActiesState;
type DeclaratieRegelState = typeof initialDeclaratieRegelState;
type SelectedFactuurState = typeof initialSelectedFactuurState;

export const facturenReducer = (
  state: FacturenState = initialFacturenState,
  action: types.GetPrestatiesForFacturenAction
) => {
  if (
    action.type === types.GET_PRESTATIES_FOR_FACTUREN ||
    action.type === types.GET_HERDECLARATIES
  )
    return action.facturen;

  return state;
};

export const selectedFactuurReducer = (
  state: SelectedFactuurState = initialSelectedFactuurState,
  action: types.SetSelectedFactuurAction
) => {
  if (action.type === types.SET_SELECTED_FACTUUR) {
    return action.selectedFactuur;
  }

  return state;
};

export const patientenReducer = (
  state: PatientenState = initialPatientenState,
  action: types.GetPrestatiesForPatientenAction
) => {
  if (action.type === types.GET_PRESTATIES_FOR_PATIENTEN)
    return action.patienten;

  return state;
};

export const middelenReducer = (
  state: MiddelenState = initialMiddelenState,
  action: types.GetPrestatiesForMiddelenAction
) => {
  if (action.type === types.GET_PRESTATIES_FOR_MIDDELEN) return action.middelen;

  return state;
};

export const actiesReducer = (
  state: ActiesState = initialActiesState,
  action: types.GetPrestatiesForActiesAction
) => {
  if (action.type === types.GET_PRESTATIES_FOR_ACTIES) return action.acties; //.map(x => ({...x, opgelost: true}));

  return state;
};

export const declaratieRegelReducer = (
  state: DeclaratieRegelState = initialDeclaratieRegelState,
  action: types.GetPrestatieForDeclaratieRegelAction
) => {
  if (action.type === types.GET_PRESTATIE_FOR_DECLARATIE_REGEL)
    return action.declaratieRegel;

  return state;
};
