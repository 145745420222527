import React from "react";
import RouterWrapper from "../RouterWrapper";

export class Template extends React.Component {
    render() {
        return (
            <>
                <RouterWrapper />
            </>
        );
    }
}
