import ActiesComponent from "../components/ActiesComponent/ActiesComponent";
import { setFilterDate } from "../actions/filterActions";
import { getPrestatiesForActies } from "../actions/prestatieActions";
import React from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from '../types/state';
import { Actie, Apotheek, TringlAlert } from "../types/model";
import { setIsLoading } from "../actions/gridviewActions";
import { getTringlAlerts } from "../actions/alertActions";
import AlertApi from "../api/AlertApi";

interface DispatchProps {
    setFilterDate: (from: string, to: string) => any;
    getPrestatiesForActies: (selectedApotheken: number[], from: string, to: string) => any;
    setIsLoading: (isLoading:boolean) => any;
    getTringlAlerts: (selectedApotheken: number[], from: string, to: string) => any;
}

interface StateProps {
    selectedApotheken: Apotheek[];
    from: string;
    to: string;
    acties: Actie[];
    isLoading: boolean;
    alerts: TringlAlert[];
}

class ActiesContainer extends React.Component<DispatchProps & StateProps> {

    setAction = async (id: number, isOpgelost: boolean, selectedApotheken: Apotheek[], from: string, to: string) => {
        await AlertApi.updateAlert(id, isOpgelost);
        this.props.getTringlAlerts(this.props.selectedApotheken.map(a => a.id), this.props.from, this.props.to);
    }

    componentDidMount() {
        //this.props.getPrestatiesForActies(this.props.selectedApotheken.map(a => a.id), this.props.from, this.props.to);
        this.props.getTringlAlerts(this.props.selectedApotheken.map(a => a.id), this.props.from, this.props.to);
    }

    render() {
        const { acties, isLoading, alerts } = this.props;

        return (
            <>
                <ActiesComponent alerts={alerts} acties={acties} onActie={this.setAction} selectedApotheken={this.props.selectedApotheken} isLoading={isLoading} from={this.props.from} to={this.props.to} />
            </>
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    selectedApotheken: state.selectedApotheken,
    from: state.filterDate.from,
    to: state.filterDate.to,
    acties: state.acties,
    isLoading: state.isLoading,
    alerts: state.alerts
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): DispatchProps => ({
    setFilterDate: (from: string, to: string) => dispatch(setFilterDate(from, to)),
    getPrestatiesForActies: (selectedApotheken: number[], from: string, to: string) => dispatch(getPrestatiesForActies(selectedApotheken, from, to)),
    setIsLoading: (isLoading:boolean) => dispatch(setIsLoading(isLoading)),
    getTringlAlerts: (selectedApotheken: number[], from: string, to: string) => dispatch(getTringlAlerts(selectedApotheken,from,to))
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiesContainer);