import React, { Component } from "react";
import { WrappedFieldProps } from "redux-form";
import classnames from "classnames";
import moment from "moment";

interface Props {
  placeholder?: string;
  label?: string;
  readOnly?: boolean;
  type?: string;
  showSideBySide?: boolean;
  defaultValue?: string;
  customWrapperClass?: string;
  customDateFormat?: string;
}

export const DateInput = (props: WrappedFieldProps & Props) => {
  const {
    input,
    label,
    type = "text",
    meta: { touched, error },
    showSideBySide,
    customWrapperClass,
    customDateFormat,
    defaultValue,
  } = props;

  let style = {};

  let wrapperClass = "";
  if (customWrapperClass) wrapperClass = customWrapperClass;
  else {
    wrapperClass =
      "field column is-horizontal is-marginless is-full-mobile is-full-tablet";

    if (showSideBySide)
      wrapperClass = classnames(wrapperClass, "is-half-desktop ");
    else wrapperClass = classnames(wrapperClass, "is-full-desktop ");
  }

  style = { paddingTop: ".5rem", paddingRight: "2rem" };

  let dateFormat = type === "month" ? "YYYY-MM" : "YYYY-MM-DD";
  if (customDateFormat) dateFormat = customDateFormat;

  return (
    <div className={wrapperClass}>
      {label && (
        <div className="field-label">
          <label
            className="label tringlLabel is-pulled-left"
            htmlFor={input.name}
            style={style}
          >
            {label}
          </label>
        </div>
      )}
      <div className="field-body">
        <input {...input} className="input" type="date" />
        {touched && error && <span className="help is-danger">{error}</span>}
      </div>
    </div>
  );
};

interface DateProps {
  value: string;
  type: string;
  defaultValue: string | undefined;
  dateFormat: string;
  input: any;
}

interface DateState {
  value: string;
}

class SimpleDateInput extends Component<DateProps, DateState> {
  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let date = moment(event.target.value);
    this.setState({ value: date.format(this.props.dateFormat) });
  };

  render() {
    let momentValue = this.state
      ? moment(this.state.value)
      : moment(this.props.defaultValue);

    return (
      <input
        {...this.props.input}
        onKeyDown={(e) => e.preventDefault()}
        type={this.props.type}
        onChange={this.onChange}
        value={momentValue.format(this.props.dateFormat)}
        className="input"
      />
    );
  }
}
