import PatientenComponent from "../components/PatientenComponent/PatientenComponent";
import { setFilterDate } from "../actions/filterActions";
import { getPrestatiesForPatienten } from "../actions/prestatieActions";
import React, { ChangeEvent } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from '../types/state';
import { Column, RowInfo, Filter } from "react-table";
import { Apotheek, Patient } from "../types/model";
import moment from "moment";
import history from '../components/history';
import matchSorter from "match-sorter";
import { setIsLoading } from "../actions/gridviewActions";
import { FilterFormProps } from "../components/PatientenFilterFormComponent/PatientenFilterFormComponent";

interface DispatchProps {
    setFilterDate: (from: string, to: string) => any;
    getPrestatiesForPatienten: (selectedApotheken: number[], from: string, to: string, bsnnummer?: number, naam?: string, geboortedatum?: string) => any;
    setIsLoading: (isLoading:boolean) => any;
}

interface StateProps {
    selectedApotheken: Apotheek[];
    from: string;
    to: string;
    patienten: Patient[];
    isLoading: boolean;
}

class PatientenContainer extends React.Component<DispatchProps & StateProps> {
    onChange = (from: string, to: string) => {
        this.props.setFilterDate(from, to);
        this.props.getPrestatiesForPatienten(this.props.selectedApotheken.map(a => a.id), from, to);
    }

    onSubmitFilters = (formValues: FilterFormProps) => {
        let van = formValues.vanMaand ? formValues.vanMaand : this.props.from;
        let tot = formValues.totMaand ? formValues.totMaand : this.props.from;

        this.props.getPrestatiesForPatienten(this.props.selectedApotheken.map(a => a.id), van, tot, formValues.bsnnummer, formValues.naam, formValues.geboortedatum );
    }

    onRowClick = (event: ChangeEvent, rowInfo: RowInfo) =>
    {
        history.push('/Detail', rowInfo.original.id );
    }

    onFilteredChanged = (filter: string, value: string) => {
        
    }

    render() {
        const { from, to, patienten, isLoading } = this.props;

        let columns: Array<Column> = [
            {
                Header: "UZOVI",
                accessor: "uzovi",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [filter.id] }),
                filterAll: true
            },
            {
                Header: "BSN",
                accessor: "bsnNummer",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [filter.id] }),
                filterAll: true
            },
            {
                Header: "Naam",
                accessor: "naam",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [filter.id] }),
                filterAll: true
            },
            {
                Header: "Voorletters",
                accessor: "voorletters",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [filter.id] }),
                filterAll: true
            },
            {
                Header: "Tussenvoegsel",
                accessor: "voorvoegsel",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [filter.id] }),
                filterAll: true
            },
            {
                Header: "Geboortedatum",
                accessor: "geboorteDatum",
                headerStyle: {
                    textAlign: "left"
                },
                Cell: props => moment(props.value).format("DD-MM-YYYY"),
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [filter.id] }),
                filterAll: true
            },
            {
                Header: "Receptnummer",
                accessor: "receptNummer",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [filter.id] }),
                filterAll: true
            },
            {
                Header: "ZI-nummer",
                accessor: "ziNummer",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [filter.id] }),
                filterAll: true
            },
            {
                Header: "Afleverdatum",
                accessor: "afleverDatum",
                headerStyle: {
                    textAlign: "left"
                },
                Cell: props => moment(props.value).format("DD-MM-YYYY"),
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [filter.id] }),
                filterAll: true
            },
            {
                Header: "Ingediend bedrag",
                accessor: "importBedrag",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [filter.id] }),
                filterAll: true
            },
            {
                Header: "TRINGL bedrag",
                accessor: "tringlBedrag",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [filter.id] }),
                filterAll: true
            },
            {
                Header: "Uitgekeerd bedrag",
                accessor: "toegekendBedrag",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [filter.id] }),
                filterAll: true
            },
            {
                Header: "Afkeurreden",
                accessor: "afkeurReden",
                headerStyle: {
                    textAlign: "left"
                },
                filterMethod: (filter: Filter, rows: any) =>
                    matchSorter(rows, filter.value, { keys: [filter.id] }),
                filterAll: true
            }
        ]

        return (
            <>
                <PatientenComponent onSubmitFilters={this.onSubmitFilters} onRowClick={this.onRowClick} onChange={this.onChange} onFilteredChanged={this.onFilteredChanged} from={from} to={to} columns={columns} patienten={patienten} isLoading={isLoading} />
            </>
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => ({
    selectedApotheken: state.selectedApotheken,
    from: state.filterDate.from,
    to: state.filterDate.to,
    patienten: state.patienten,
    isLoading: state.isLoading
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): DispatchProps => ({
    setFilterDate: (from: string, to: string) => dispatch(setFilterDate(from, to)),
    getPrestatiesForPatienten: (selectedApotheken: number[], from: string, to: string, bsnnummer?: number, naam?: string, geboortedatum?: string) => 
        dispatch(getPrestatiesForPatienten(selectedApotheken, from, to, bsnnummer, naam, geboortedatum)),
    setIsLoading: (isLoading:boolean) => dispatch(setIsLoading(isLoading))
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientenContainer);