import { Action } from "redux";
import { Ap304BestandProgress, Ap304BestandTotal } from "../types/model";

export const GET_AP304BESTANDEN = "GET_AP304BESTANDEN";
export const GET_AP304BESTANDENTOTAL = "GET_AP304BESTANDENTOTAL";

export interface GetAp304BestandenTotalAction extends Action<string> {
    ap304BestandTotal: Ap304BestandTotal
}

export interface GetAp304BestandenAction extends Action<string> {
    ap304BestandProgress: Ap304BestandProgress
}