import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import classnames from 'classnames';

interface Props {
    placeholder?: string;
    label?: string;
    readOnly?: boolean
    type?: string;
    showSideBySide?: boolean,
    customWrapperClass?: string
}

export const TextInput = (props: WrappedFieldProps & Props) => {
    const { input, label, type = "text", meta: { touched, error }, placeholder, showSideBySide, customWrapperClass, readOnly } = props;

    let style = {  };

    let wrapperClass = "";
    
    if(customWrapperClass)
        wrapperClass = customWrapperClass;
    else {
        wrapperClass = "field column is-horizontal is-marginless is-full-mobile is-full-tablet";

        if (showSideBySide)
            wrapperClass = classnames(wrapperClass, "is-half-desktop ");
        else
            wrapperClass = classnames(wrapperClass, "is-full-desktop ");
    }

    style = { paddingTop: '.5rem', paddingRight: '2rem' };
    return (
        <div className={wrapperClass}>
            {label && <div className="field-label"><label className="label tringlLabel is-pulled-left" htmlFor={input.name} style={style}>{label}</label></div>}
            <div className="field-body">
                <input
                    {...input}
                    type={type}
                    className={classnames("input", touched && error && "is-danger")}
                    placeholder={placeholder}
                    readOnly={readOnly}
                />
                {touched && error && <span className="help is-danger">{error}</span>}
            </div>
        </div>
    );
}
