import { Action } from "redux";
import { Account } from "../types/model";

export const GET_ACCOUNT = "GET_ACCOUNT";
export const REQUEST_CHANGE_PASSWORD = "REQUEST_CHANGE_PASSWORD";
export const SUCCESS_CHANGE_PASSWORD = "SUCCESS_CHANGE_PASSWORD";
export const FAILURE_CHANGE_PASSWORD = "FAILURE_CHANGE_PASSWORD";

export interface ChangePasswordAction extends Action<string> {
    changing: boolean;
}

export interface GetAccountAction extends Action<string> {
    account: Account;
}
