import React from "react";
import { Field, reduxForm, InjectedFormProps } from "redux-form";
import { TextInput } from "../TextInput";
import moment from "moment";
import { DateInput } from "../DateInput";

export interface FilterFormProps {
  bsnnummer?: string;
  naam?: string;
  geboortedatum?: string;
  vanMaand?: string;
  totMaand?: string;
}

export interface OwnProps {
  hideMonth?: boolean;
}

class PatientenFilterFormComponent extends React.Component<
  OwnProps & InjectedFormProps<FilterFormProps, OwnProps>
> {
  constructor(props) {
    super(props);

    this.submitRef = React.createRef();
  }
  componentDidMount(): void {
    const query = new URLSearchParams(new URL(window.location.href).search);

    if (query.has("bsn")) {
      this.props.change("bsnnummer", query.get("bsn")!);
      this.props.change(
        "vanMaand",
        moment().subtract(1, "month").format("YYYY-MM-DD")
      );
      this.props.change("totMaand", moment().format("YYYY-MM-DD"));

      console.log(this.submitRef.current);
      this.submitRef.current.click();
    }
  }

  render(): React.ReactNode {
    const { handleSubmit, pristine, submitting, hideMonth } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        {!hideMonth && (
          <>
            <Field
              component={DateInput}
              type="date"
              name="vanMaand"
              placeholder="Van"
              label="Van"
              customWrapperClass="field column"
            />

            <Field
              component={DateInput}
              type="date"
              name="totMaand"
              placeholder="Tot"
              label="Tot"
              customWrapperClass="field column"
            />
          </>
        )}
        <Field
          component={TextInput}
          type="text"
          name="bsnnummer"
          className="input"
          placeholder="BSN"
          label="BSN"
          customWrapperClass="field column"
        />
        <Field
          component={TextInput}
          type="text"
          name="naam"
          className="input"
          placeholder="Naam"
          label="Naam"
          customWrapperClass="field column"
        />
        <Field
          component={DateInput}
          type="date"
          name="geboortedatum"
          placeholder="Geboortedatum"
          defaultValue=""
          label="Geboortedatum"
          customWrapperClass="field column"
        />
        <div className="field column">
          <button ref={this.submitRef} className="button is-dark" type="submit">
            Zoeken
          </button>
        </div>
      </form>
    );
  }
}

export default reduxForm<FilterFormProps, OwnProps>({
  form: "PatientenFilterForm",
})(PatientenFilterFormComponent);
