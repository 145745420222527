import { Action } from "redux";
import { Factuur, Patient, Middel, Actie, DeclaratieRegel } from "../types/model";

export const GET_PRESTATIES_FOR_FACTUREN = "GET_PRESTATIES_FOR_FACTUREN";
export const GET_HERDECLARATIES = "GET_HERDECLARATIES";
export const SET_SELECTED_FACTUUR = "SET_SELECTED_FACTUUR";
export const GET_PRESTATIES_FOR_PATIENTEN = "GET_PRESTATIES_FOR_PATIENTEN";
export const GET_PRESTATIES_FOR_MIDDELEN = "GET_PRESTATIES_FOR_MIDDELEN";
export const GET_PRESTATIES_FOR_ACTIES = "GET_PRESTATIES_FOR_ACTIES";
export const GET_PRESTATIE_FOR_DECLARATIE_REGEL = "GET_PRESTATIE_FOR_DECLARATIE_REGEL";
export const SET_ACTION_STATUS = "SET_ACTION_STATUS";

export interface GetPrestatiesForFacturenAction extends Action<string> {
    facturen: Factuur[];
}

export interface SetSelectedFactuurAction extends Action<string> {
    selectedFactuur: string;
}

export interface GetPrestatiesForPatientenAction extends Action<string> {
    patienten: Patient[];
}

export interface GetPrestatiesForMiddelenAction extends Action<string> {
    middelen: Middel[];
}

export interface GetPrestatiesForActiesAction extends Action<string> {
    acties: Actie[];
}

export interface GetPrestatieForDeclaratieRegelAction extends Action<string> {
    declaratieRegel: DeclaratieRegel;
}
