import * as React from "react";
import {
  Dashboard,
  Facturen,
  Patienten,
  Middelen,
  Acties,
  DeclaratieRegelDetail,
  FactuurDetail,
  Account,
  UploadPage,
  ZorgverzekeraarProfielPage,
  IncontinentieProfielen,
  IncontinentieProfiel,
  Herdeclaraties,
} from "../pages";
import { Router, Route, Switch } from "react-router-dom";
import HeaderContainer from "../containers/HeaderContainer";
import history from "./history";

const RouterWrapper: React.FunctionComponent = () => (
  <>
    <Router history={history}>
      <>
        <HeaderContainer />
        <Switch>
          <Route exact={true} path="/" component={Dashboard} />
          <Route exact={true} path="/Account" component={Account} />
          <Route exact={true} path="/Facturen" component={Facturen} />
          <Route exact={true} path="/FactuurDetail" component={FactuurDetail} />
          <Route exact={true} path="/Patienten" component={Patienten} />
          <Route exact={true} path="/Middelen" component={Middelen} />
          <Route
            exact={true}
            path="/Herdeclaraties"
            component={Herdeclaraties}
          />
          <Route exact={true} path="/Acties" component={Acties} />
          <Route
            exact={true}
            path="/Detail"
            component={DeclaratieRegelDetail}
          />
          <Route
            exact={true}
            path="/ZorgverzekeraarProfiel"
            component={ZorgverzekeraarProfielPage}
          />
          <Route exact={true} path="/Upload" component={UploadPage} />
          <Route
            exact={true}
            path="/InconinentieProfielen"
            component={IncontinentieProfielen}
          />
          <Route
            exact={true}
            path="/InconinentieProfiel/:id"
            component={IncontinentieProfiel}
          />
        </Switch>
      </>
    </Router>
  </>
);

export default RouterWrapper;
