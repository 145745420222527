import * as actionTypes from "../constants/accountActionTypes";
import AccountApi from '../api/AccountApi';
import { AsyncAction } from ".";

export const getAccount = (): AsyncAction<actionTypes.GetAccountAction> => {
    return async (dispatch) => {
        const account = await AccountApi.getAccount();

        return dispatch({ type: actionTypes.GET_ACCOUNT, account });
    };
}

export const changePassword = (oldPassword: string, newPassword: string): AsyncAction<actionTypes.ChangePasswordAction> => {
    return async (dispatch) => {
        dispatch({ type: actionTypes.REQUEST_CHANGE_PASSWORD, changing: true });
        try {
            await AccountApi.changePassword(oldPassword, newPassword);

            return dispatch({ type: actionTypes.SUCCESS_CHANGE_PASSWORD, changing: false });
        }
        catch (err) {
            return dispatch({ type: actionTypes.FAILURE_CHANGE_PASSWORD, changing: false });
        }
    };
}