import * as types from "../constants/apotheekActionTypes";
import { Apotheek, ApotheekAp304 } from "../types/model";

const initialState: Array<Apotheek> = [];
const initialStateAp304: Array<ApotheekAp304> = [];

type State = typeof initialState
type StateAp304 = typeof initialStateAp304

export const apothekenReducer = (
    state: State = initialState,
    action: types.GetApothekenAction
) => {
    if (action.type === types.GET_APOTHEKEN)
        return action.apotheken;

    return state;
};

export const apothekenAp304Reducer = (
    state: StateAp304 = initialStateAp304,
    action: types.GetApothekenAp304Action
) => {
    if (action.type === types.GET_APOTHEKENAP304){
        return action.apotheekAp304;
    }

    return state;
};

export const selectedApothekenReducer = (
    state: State = initialState,
    action: types.SetSelectedApothekenAction
) => {
    if (action.type === types.SET_SELECTED_APOTHEKEN) {
        return action.selectedApotheken;
    }

    return state;
};
