import ApiClient from './ApiClient';
import { FileModel } from '../types/model';


const endpoint = 'upload';

export default class UploadApi {
    public static async uploadFile(data: FileModel[]): Promise<void> {        
        await ApiClient.doHttpRequest<void>('post', `${endpoint}/uploadfile`,  { data });
    }
}